import React, { Component } from 'react'

export default class Footer extends Component {
  render() {
    return (
      <footer>
        <div className='container workanimation'>
          <h4><a className='fuoi-txt' href='https://www.instagram.com/justcasuals/' target='_blank'>Follow us on Instagram </a></h4>
          <div className='footer-border'></div>
          <div className='footer-menu'>
            <ul className="foot-menu">
              <li><a href="/about-us">About</a></li>
              <li><a href="/business-opportunities">Business Opportunities</a></li>
              <li><a href="/excess-inventory">Excess Inventory</a></li>
              <li><a href="/find-a-store">Find a Store</a></li>
              <li><a href="/" className='modal-buu' data-bs-toggle="modal" data-bs-target="#exampleModal">Contact</a></li>
            </ul>
          </div>
          <div className='footer-c'>
            <h5>Copyright © 2023 Just Casuals. <br className='d-block d-lg-none d-md-none' /> All rights reserved</h5>
            <a target="_blank" href='https://fingerprintscreative.com'>Made by Fingerprints Creative</a>
          </div>
        </div>
      </footer>
    )
  }
}
