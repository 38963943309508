import React, { Component } from 'react'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Lottie from 'lottie-react'
import Footer from '../components/footer'
import Header from '../components/header'
import Zizabout1 from './../assets/img/jc-about-iz-1.json'
import Zizabout2 from './../assets/img/jc-about-iz-2.json'
import Zizabout3 from './../assets/img/jc-about-iz-3.json'
import Zizabout4 from './../assets/img/jc-about-iz-4.json'
import AboutHeroTop from './../assets/img/about-hero-top.png'
import AboutHeroBottom from './../assets/img/about-hero-bottom.png'
import AboutHeroBottomD from './../assets/img/about-hero-bottom-d.svg'
import AboutRTImg1 from './../assets/img/about-rt-img1.png'
import AboutRTImg2 from './../assets/img/about-rt-img2.svg'
import AboutRTImg4 from './../assets/img/about-rt-img4.svg'



export default class About extends Component {

    processGsap = () =>{

    gsap.registerPlugin(ScrollTrigger)

        ScrollTrigger.saveStyles(".mobile, .desktop");

        ScrollTrigger.matchMedia({
            
            // desktop
            "(min-width: 1024px)": function() {

                // Image Entrance 1
                gsap.set(".ifscroll1", { scale: 0, opacity: 0.2 });
                gsap.to('.ifscroll1', {
                  opacity: 1,
                  scale: 1,
                  ease: "easeOut",
                  scrollTrigger: {
                    trigger: ".aboutscroll",
                    start: "top center",
                    end: "bottom top",
                  }
                })
              
                // Image Entrance 2
                gsap.set(".ifscroll2", { scale: 0, opacity: 0.2 });
                gsap.to('.ifscroll2', {
                  opacity: 1,
                  scale: 1,
                  ease: "easeOut",
                  scrollTrigger: {
                    trigger: ".aboutscroll",
                    start: "top center",
                    end: "bottom top",

                  }
                })

                // Image Entrance 3
                gsap.set(".ifscroll3", { scale: 0, opacity: 0.2 });
                gsap.to('.ifscroll3', {
                  opacity: 1,
                  scale: 1,
                  delay: 1,
                  ease: "easeOut",
                  scrollTrigger: {
                    trigger: ".about-rt",
                    start: "top center",
                    end: "bottom top",

                  }
                })

                // Image Entrance 4
                gsap.set(".ifscroll4", { scale: 0, opacity: 0.2 });
                gsap.to('.ifscroll4', {
                  opacity: 1,
                  scale: 1,
                  ease: "easeOut",
                  scrollTrigger: {
                    trigger: ".about-rt",
                    start: "top center",
                    end: "bottom top",

                  }
                })

                // Image Entrance 5
                gsap.set(".ifscroll5", { scale: 0, opacity: 0 });
                gsap.to('.ifscroll5', {
                  opacity: 1,
                  scale: 1,
                  delay: 1,
                  ease: "easeOut",
                  scrollTrigger: {
                    trigger: ".about-rt",
                    start: "top center",
                    end: "bottom top",

                  }
                })

                // Image Entrance json
                gsap.set(".jsona1", { scale: 1, opacity: 0 });
                gsap.to('.jsona1', {
                  opacity: 1,
                  scale: 1,
                  delay: 0.5,
                  ease: "easeOut",
                  scrollTrigger: {
                    trigger: ".process-approach",
                    start: "top center",
                    end: "bottom top",

                  }
                })
                // Image Entrance json
                gsap.set(".jsona2", { scale: 1, opacity: 0 });
                gsap.to('.jsona2', {
                  opacity: 1,
                  scale: 1,
                  // delay: 0.5,
                  ease: "easeOut",
                  scrollTrigger: {
                    trigger: ".about-hero-cont-pp",
                    start: "top center",
                    end: "bottom top",

                  }
                })
                // Image Entrance json
                gsap.set(".jsona3", { scale: 1, opacity: 0 });
                gsap.to('.jsona3', {
                  opacity: 1,
                  scale: 1,
                  delay: 1,
                  ease: "easeOut",
                  scrollTrigger: {
                    trigger: ".about-hero",
                    start: "top center",
                    end: "bottom top",

                  }
                })



              // Image Float 1
              gsap.to('.ifscroll1', {
                yPercent: -20,
                xPercent: -10,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".aboutscroll",
                  start: "top center",
                  end: "bottom top",
                  scrub: true
                }
              })

              // Image Float 2
              gsap.to('.ifscroll2', {
                yPercent: -20,
                xPercent: -20,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".aboutscroll",
                  start: "top center",
                  end: "bottom top",
                  scrub: true
                }
              })

              // Image Float 3
              gsap.to('.ifscroll3', {
                yPercent: -20,
                xPercent: -10,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".about-rt",
                  start: "top center",
                  end: "bottom top",
                  scrub: true
                }
              })

              // Image Float 4
              gsap.to('.ifscroll4', {
                yPercent: 10,
                xPercent: 30,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".about-rt",
                  start: "top center",
                  end: "bottom top",
                  scrub: true
                }
              })
            },
        
            // mobile
            "(max-width: 799px)": function() {


              // Image Entrance 1
              gsap.set(".ifscroll1", { scale: 0, opacity: 0.2 });
              gsap.to('.ifscroll1', {
                opacity: 1,
                scale: 1,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".aboutscroll",
                  start: "top center",
                  end: "bottom top",
                }
              })
              // Image Float 1
              gsap.to('.ifscroll1', {
                yPercent: -10,
                xPercent: 20,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".aboutscroll",
                  start: "top center",
                  end: "bottom top",
                  scrub: true
                }
              })

              // Image Entrance 2
              gsap.set(".ifscroll2", { scale: 0, opacity: 0.2 });
              gsap.to('.ifscroll2', {
                opacity: 1,
                scale: 1,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".aboutscroll",
                  start: "top center",
                  end: "bottom top",

                }
              })
              // Image Float 2
              gsap.to('.ifscroll2', {
                yPercent: -20,
                xPercent: -10,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".aboutscroll",
                  start: "top center",
                  end: "bottom top",
                  scrub: true
                }
              })

              

              // Image Entrance 5
              gsap.set(".ifscroll5", { scale: 0, opacity: 0.2 });
              gsap.to('.ifscroll5', {
                opacity: 1,
                scale: 1,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".aboutscroll",
                  start: "top top",
                  end: "bottom top",

                }
              })
              // Image Entrance 6
              gsap.set(".ifscroll6", { scale: 0, opacity: 0.2 });
              gsap.to('.ifscroll6', {
                opacity: 1,
                scale: 1,
                delay: 0.8,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".bo-home",
                  start: "top center",
                  end: "bottom top",

                }
              })
              // Image Entrance 7
              gsap.set(".ifscroll7", { scale: 0, opacity: 0.2 });
              gsap.to('.ifscroll7', {
                opacity: 1,
                scale: 1,
                delay: 0.5,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".cl-slider",
                  start: "top center",
                  end: "bottom top",

                }
              })

              // Image Entrance json
              gsap.set(".jsona1", { scale: 1, opacity: 0 });
              gsap.to('.jsona1', {
                opacity: 1,
                scale: 1,
                delay: 0.6,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".process-approach",
                  start: "top center",
                  end: "bottom top",

                }
              })
              // Image Entrance json
              gsap.set(".jsona2", { scale: 1, opacity: 0 });
              gsap.to('.jsona2', {
                opacity: 1,
                scale: 1,
                delay: 0.7,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".about-hero-cont-pp",
                  start: "top bottom",
                  end: "bottom top",

                }
              })
              // Image Entrance json
              gsap.set(".jsona3", { scale: 1, opacity: 0 });
              gsap.to('.jsona3', {
                opacity: 1,
                scale: 1,
                delay: 1,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".about-hero",
                  start: "top center",
                  end: "bottom top",

                }
              })
                
            }, 
        
            // all 
            "all": function() {

              // Image Entrance 3
              gsap.set(".ifscroll3", { scale: 0, opacity: 0.2 });
              gsap.to('.ifscroll3', {
                opacity: 1,
                scale: 1,
                delay: 1,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".about-rt",
                  start: "top center",
                  end: "bottom top",

                }
              })
              // Image Float 3
              gsap.to('.ifscroll3', {
                yPercent: -20,
                xPercent: -10,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".about-rt",
                  start: "top center",
                  end: "bottom top",
                  scrub: true
                }
              })

              // Image Entrance 4
              gsap.set(".ifscroll4", { scale: 0, opacity: 0.2 });
              gsap.to('.ifscroll4', {
                opacity: 1,
                scale: 1,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".about-rt",
                  start: "top center",
                  end: "bottom top",

                }
              })
              // Image Float 4
              gsap.to('.ifscroll4', {
                yPercent: 10,
                xPercent: 30,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".about-rt",
                  start: "top center",
                  end: "bottom top",
                  scrub: true
                }
              })

            }
        
        });


    gsap.set(".workanimation", { visibility: "hidden" });
    ScrollTrigger.batch(".workanimation", {
        onEnter: batch => {
            gsap.from(batch, {
                duration: 1,
                delay: 0.5,
                yPercent: 50,
                scale: 1,
                autoAlpha: 0,
                ease: "Power3.easeOut",
                stagger: 0.4,
                visibility: "visible",
            })
        },
        toggleActions: "play none none none",
        once: true,
        start: 'top 80%',
        end: 'top 40%',
        markers: false,
    });

      //ProcessIMG 
      gsap.set(".cp-image-reveal", {opacity: 0});
      gsap.to(".cp-image-reveal", {
        opacity: 1,
        ease: "Power3.easeOut",
        delay: 1,
        duration: 1
      });

      //Our APPROACH
      gsap.set(".process-approach .line span", {y: 180, rotateZ: 6});
      gsap.to(".process-approach .line span", {
        y: 0,
        rotateZ: 0,
        ease: "Power3.easeOut",
        delay: 0,
        stagger: {
          amount: 0.2
        },
        scrollTrigger: {
          trigger: ".process-approach .line span",
          start: "top 90%",
        }
      });


        //OUR PROCESS 
        gsap.set(".our-process-txt1", { y: "27vw", rotateZ:"5deg" });
        gsap.to(".our-process-txt1", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.7,
        scrollTrigger: {
            trigger: ".our-process-txt1",
            start: "top 80%"
        }
        });
        gsap.set(".our-process-txt2", { y: "27vw", rotateZ:"5deg", });
        gsap.to(".our-process-txt2", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        duration: 0.71,
        scrollTrigger: {
            trigger: ".our-process-txt2",
            start: "top 100%"
        }
        });

        // gsap.set(".purpose::before", {opacity: 0, yPercent: 40});
        //   gsap.to(".purpose::before", {
        //       delay: 1,
        //       opacity: 1,
        //       yPercent: 0,
        //       duration: 0.7
        //   });
    }


    componentDidMount(){
        this.processGsap()
    }

    // state = {
    //   isStopped: true,
    //   isPaused: false,
    //   loop: true,
    //   autoplay: false,
    // };

  render() {

    // const defaultOptions = {
    //   loop: false,
    //   autoplay: true,
    //   // animationData: animationData,
    //   rendererSettings: {
    //     preserveAspectRatio: "xMidYMid slice",
    //   },
    // };


    return (
      <>
        <Header />

        <div className='about-page'>

            {/* Hero Section */}
            <div className='about-hero'>
                <div className='container process-approach'>
                    <div className='about-hero-b-cont d-none d-lg-block d-md-block'>
                      <h1 className='line'>
                        <span>Driven by <br className='d-block d-lg-none d-md-none' /> <span className='purpose'>purpose,</span></span>
                      </h1>
                      <h1 className='line'>
                        <span>
                          guided by&nbsp;
                          <span className='values'> values.
                            
                          </span>
                        </span>
                      </h1>
                      <div className='about-lott jsona1'>
                        <Lottie
                          // options={defaultOptions}
                          animationData={Zizabout1}
                        />
                      </div>
                    </div>
                    <div className='about-hero-b-cont d-block d-lg-none d-md-none'>
                      <h1 className='line'>
                        <span>Driven by </span>
                      </h1>
                      <h1 className='line'>
                        <span className='purpose'>purpose,</span>
                      </h1>
                      <h1 className='line'>
                        <span>guided by</span>
                      </h1>
                      <h1 className='line'>
                          <span className='values'> values.</span>
                      </h1>
                      <div className='about-lott jsona1'>
                        <Lottie
                          // options={defaultOptions}
                          animationData={Zizabout1}
                        />
                      </div>
                    </div>
                    <div className='about-hero-content'>
                        <div className='row resp-flx-dr aboutscroll'>
                            <div className='col-xl-4 col-lg-5 image-side'>
                                <img src={AboutHeroTop} className="img-fluid about-hero-top ifscroll1" alt='Just-casuals' />
                                <img src={AboutHeroBottom} className="img-fluid about-hero-bottom ifscroll2" alt='Just-casuals' />
                            </div>
                            <div className='col-xl-8 col-lg-7 align-self-center'>
                              <div className='about-hero-cont-pp'>

                                <div className='about-lott2 jsona2'>
                                  <Lottie
                                    loop
                                    animationData={Zizabout2}
                                    play
                                  />
                                </div>
                                {/* <img className='img-fluid about-hero-pp' src={AboutHeroPP} alt='Just-casuals' /> */}

                                <p className='workanimation'>We are a one-stop-shop for apparel for everyone in the family. Just Casuals is based out of Chennai and has stores across the country. By focusing on opportunistic buying strategies, we are able to offer great savings to you. Walk into any of our stores, shop more and pay less.</p>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='about-lott5 jsona3'>
                  <Lottie 
                    loop
                    animationData={Zizabout4}
                    play
                  />
                </div>
                {/* <img src={AboutHeroFloat} className="about-hero-float" alt='Just-casuals' /> */}
                <img src={AboutHeroBottomD} className="about-hero-bottomd" alt='Just-casuals' />
            </div>
            {/* Hero Section End */}

            {/* About RT Content */}
            <div className='about-rt'>
              <div className='container'>
                <img className='img-fluid about-rt-img2 ifscroll4' src={AboutRTImg2} alt='Just-casuals' />
                <div className='row'>
                  <div className='col-lg-8 align-self-center'>
                    <div className='about-rt-cont'>
                      <div className='about-lott3 workanimation'>
                        <Lottie
                          loop
                          animationData={Zizabout3}
                          play
                        />
                      </div>
                      {/* <img className='img-fluid about-rt-img3' src={AboutRTImg3} alt='Just-casuals' /> */}
                      <p className='workanimation'>We cater to all needs from formal wear to casual wear in an affordable price range. Our retail stores are open 7 days a week so that you can visit whenever it suits you best!</p>
                      {/* <img className='img-fluid about-rt-img3-1' src={AboutRTImg3} alt='Just-casuals' /> */}
                      <div className='about-lott4 workanimation'>
                        <Lottie
                          loop
                          animationData={Zizabout3}
                          play
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4'>
                    <div className='position-relative'>
                      <img className='img-fluid ifscroll3' src={AboutRTImg1} alt='Just-casuals' />
                      <img className='img-fluid about-rt-img4 ifscroll5' src={AboutRTImg4} alt='Just-casuals' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* About RT Content End */}


        </div>

        <Footer />
      </>
    )
  }
}
