import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './styles/main.css'
import './styles/responsive.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from './pages/home';
import BusinessOppurtunities from './pages/business';
import About from './pages/about';
import ExcessInventory from './pages/inventory';
import Dummy from './pages/dummy';
import Store_Locator from './pages/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter basename='/'>
    <Routes>
      <Route exact path='/' element={<Home />} />
      <Route exact path='/about-us' element={<About />} />
      <Route exact path='/business-opportunities' element={<BusinessOppurtunities />} />
      <Route exact path='/excess-inventory' element={<ExcessInventory />} />
      <Route exact path='/find-a-store' element={<Store_Locator />} />
      <Route exact path='/dummy' element={<Dummy />} />
    </Routes>
  </BrowserRouter>
);
