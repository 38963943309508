import React, { Component } from 'react'
import InstaFeed from '../components/insta.js'
import Header from '../components/header'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
// import SplitText from 'gsap/SplitText'
import Slider from 'react-slick'
import Lottie from 'lottie-react'
// import Arrowjson from './../assets/img/Arrow.json'
import Arrowjson1 from './../assets/img/arrow-fill-02.json'
// import Arrowvdo from './../assets/img/Arrow.mp4'
import Hheroimg from './../assets/img/jc-home-hero-img1-2.png'
import Hheroimgresp from './../assets/img/jc-home-hero-img1-2resp.png'
import Line1 from './../assets/img/line-img-1.svg'
import Line2 from './../assets/img/line-img-2.svg'
import Line3 from './../assets/img/line-img-3.svg'
import Line4 from './../assets/img/line-img-4.svg'
import HAimg1 from './../assets/img/h-about-img-1.svg'
import HAimg2 from './../assets/img/h-about-img-2.png'
import HAimg3 from './../assets/img/h-about-img-3.png'
import HAimg4 from './../assets/img/h-about-img-4.png'
import HAarrow from './../assets/img/h-about-img-arrow.svg'
import Hboimg from './../assets/img/h-bo-img1.png'
import Feofeimg from './../assets/img/feofe-img.png'
import Slideimg1 from './../assets/img/h-slide-img1.png'
import Slideimg2 from './../assets/img/h-slide-img2.png'
import Slideimg3 from './../assets/img/h-slide-img3.png'
import Slideimg4 from './../assets/img/h-slide-img4.png'
import Slideimg5 from './../assets/img/h-slide-img5.png'
import * as axios from 'axios';
import { useForm, Controller } from "react-hook-form";

export default class Home extends Component {

    homeGsap = () =>{


        gsap.registerPlugin(ScrollTrigger)

        ScrollTrigger.saveStyles(".mobile, .desktop");

        ScrollTrigger.matchMedia({
            
            // desktop
            "(min-width: 1024px)": function() {
                
                gsap.set(".extra-button-y", {opacity: 0, yPercent: 40});
                gsap.to(".extra-button-y", {
                    delay: 1,
                    opacity: 1,
                    yPercent: 0,
                    duration: 0.7
                });
                gsap.set(".extra-button-x", {opacity: 0, xPercent: 100});
                gsap.to(".extra-button-x", {
                    delay: 0.2,
                    opacity: 1,
                    xPercent: 0,
                    ease: "Power2.easeOut",
                    duration: 1
                });
                gsap.set(".extra-button-x1", {opacity: 0, xPercent: 100});
                gsap.to(".extra-button-x1", {
                    delay: 1,
                    opacity: 1,
                    xPercent: 0,
                    duration: 1
                });
                gsap.set(".extra-button-x2", {opacity: 0, xPercent: -200});
                gsap.to(".extra-button-x2", {
                    delay: 1,
                    opacity: 1,
                    xPercent: 0,
                    duration: 1,
                    scrollTrigger: {
                      trigger: ".bo-home",
                      start: "top center",
                      end: "bottom top",
                    }
                });
                gsap.set(".extra-button-x3", {opacity: 0, xPercent: -100});
                gsap.to(".extra-button-x3", {
                    delay: 1,
                    opacity: 1,
                    xPercent: 0,
                    duration: 1,
                    scrollTrigger: {
                      trigger: ".bo-home",
                      start: "top center",
                      end: "bottom top",
                    }
                });
                gsap.set(".extra-button-x4", {opacity: 0, xPercent: -100});
                gsap.to(".extra-button-x4", {
                    delay: 1,
                    opacity: 1,
                    xPercent: 0,
                    duration: 1,
                    scrollTrigger: {
                      trigger: ".bo-home",
                      start: "top center",
                      end: "bottom top",
                    }
                });
                gsap.set(".extra-button-x5", {opacity: 0, xPercent: -100});
                gsap.to(".extra-button-x5", {
                    delay: 1.5,
                    opacity: 1,
                    xPercent: 0,
                    duration: 1,
                    scrollTrigger: {
                      trigger: ".feofe-home-cont",
                      start: "top center",
                      end: "bottom top",
                    }
                });

                gsap.set(".extra-button-x6", {opacity: 0, xPercent: 100});
                gsap.to(".extra-button-x6", {
                    delay: 0,
                    opacity: 1,
                    xPercent: 0,
                    duration: 1,
                    scrollTrigger: {
                      trigger: ".cl-slider",
                      start: "top center",
                      end: "bottom top",
                    }
                });

                // Image Entrance 1
                gsap.set(".ifscroll1", { scale: 0, opacity: 0.2 });
                gsap.to('.ifscroll1', {
                  opacity: 1,
                  scale: 1,
                  ease: "easeOut",
                  scrollTrigger: {
                    trigger: ".aboutscroll",
                    start: "top center",
                    end: "bottom top",
                  }
                })
              
                // Image Entrance 2
                gsap.set(".ifscroll2", { scale: 0, opacity: 0.2 });
                gsap.to('.ifscroll2', {
                  opacity: 1,
                  scale: 1,
                  ease: "easeOut",
                  scrollTrigger: {
                    trigger: ".aboutscroll",
                    start: "top center",
                    end: "bottom top",

                  }
                })
              
                // Image Entrance 3
                gsap.set(".ifscroll3", { scale: 0, opacity: 0.2 });
                gsap.to('.ifscroll3', {
                  opacity: 1,
                  scale: 1,
                  ease: "easeOut",
                  scrollTrigger: {
                    trigger: ".aboutscroll",
                    start: "top center",
                    end: "bottom top",

                  }
                })
              
                // Image Entrance 4
                gsap.set(".ifscroll4", { scale: 0, opacity: 0.2 });
                gsap.to('.ifscroll4', {
                  opacity: 1,
                  scale: 1,
                  ease: "easeOut",
                  scrollTrigger: {
                    trigger: ".aboutscroll",
                    start: "top center",
                    end: "bottom top",

                  }
                })

                // Image Entrance 5
                gsap.set(".ifscroll5", { scale: 0, opacity: 0.2 });
                gsap.to('.ifscroll5', {
                  opacity: 1,
                  scale: 1,
                  ease: "easeOut",
                  scrollTrigger: {
                    trigger: ".aboutscroll",
                    start: "top center",
                    end: "bottom top",

                  }
                })
                // Image Entrance 6
                gsap.set(".ifscroll6", { scale: 0, opacity: 0.2 });
                gsap.to('.ifscroll6', {
                  opacity: 1,
                  scale: 1,
                  delay: 0.8,
                  ease: "easeOut",
                  scrollTrigger: {
                    trigger: ".bo-home",
                    start: "top center",
                    end: "bottom top",

                  }
                })
                // Image Entrance 7
                gsap.set(".ifscroll7", { scale: 0, opacity: 0.2 });
                gsap.to('.ifscroll7', {
                  opacity: 1,
                  scale: 1,
                  delay: 0.5,
                  ease: "easeOut",
                  scrollTrigger: {
                    trigger: ".cl-slider",
                    start: "top center",
                    end: "bottom top",

                  }
                })
              

              // Image Float 1
              gsap.to('.ifscroll1', {
                yPercent: -50,
                xPercent: -30,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".aboutscroll",
                  start: "top center",
                  end: "bottom top",
                  scrub: true
                }
              })

              // Image Float 2
              gsap.to('.ifscroll2', {
                yPercent: -50,
                xPercent: -40,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".aboutscroll",
                  start: "top center",
                  end: "bottom top",
                  scrub: true
                }
              })

              // Image Float 3
              gsap.to('.ifscroll3', {
                yPercent: -150,
                xPercent: -10,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".aboutscroll",
                  start: "top center",
                  end: "bottom top",
                  scrub: true
                }
              })

              // Image Float 4
              gsap.to('.ifscroll4', {
                yPercent: -190,
                xPercent: 40,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".aboutscroll",
                  start: "top center",
                  end: "bottom top",
                  scrub: true
                }
              })

              gsap.set(".our-process-txt1", { y: "27vw", rotateZ:"5deg" });
              gsap.to(".our-process-txt1", {
                y: 0,
                rotateZ:"0deg",
                ease: "Power3.easeOut",
                duration: 0.7,
                scrollTrigger: {
                    trigger: ".our-process-txt1",
                    start: "top 80%"
                }
              });
              gsap.set(".our-process-txt2", { y: "27vw", rotateZ:"5deg", });
              gsap.to(".our-process-txt2", {
                y: 0,
                rotateZ:"0deg",
                ease: "Power3.easeOut",
                duration: 0.72,
                scrollTrigger: {
                    trigger: ".our-process-txt2",
                    start: "top 100%"
                }
              });

            }, 
        
            // mobile
            "(max-width: 799px)": function() {

              gsap.set(".extra-button-y", {opacity: 0, yPercent: 40});
              gsap.to(".extra-button-y", {
                  delay: 1,
                  opacity: 1,
                  yPercent: 0,
                  duration: 0.7
              });
              gsap.set(".extra-button-x", {opacity: 0, xPercent: 100});
              gsap.to(".extra-button-x", {
                  delay: 0,
                  opacity: 1,
                  xPercent: 0,
                  ease: "Power2.easeOut",
                  duration: 1
              });
              gsap.set(".extra-button-x1", {opacity: 0, xPercent: 100});
              gsap.to(".extra-button-x1", {
                  delay: 1,
                  opacity: 1,
                  xPercent: 0,
                  duration: 1
              });

              // Image Entrance 1
              gsap.set(".ifscroll1", { scale: 0, opacity: 0.2 });
              gsap.to('.ifscroll1', {
                opacity: 1,
                scale: 1,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".aboutscroll",
                  start: "top center",
                  end: "bottom top",
                }
              })
              // Image Float 1
              gsap.to('.ifscroll1', {
                yPercent: -30,
                xPercent: -10,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".aboutscroll",
                  start: "top center",
                  end: "bottom top",
                  scrub: true
                }
              })

              // Image Entrance 2
              gsap.set(".ifscroll2", { scale: 0, opacity: 0.2 });
              gsap.to('.ifscroll2', {
                opacity: 1,
                scale: 1,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".aboutscroll",
                  start: "top center",
                  end: "bottom top",

                }
              })
              // Image Float 2
              gsap.to('.ifscroll2', {
                yPercent: -20,
                xPercent: -10,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".aboutscroll",
                  start: "top center",
                  end: "bottom top",
                  scrub: true
                }
              })

              // Image Entrance 5
              gsap.set(".ifscroll5", { scale: 0, opacity: 0.2 });
              gsap.to('.ifscroll5', {
                opacity: 1,
                scale: 1,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".aboutscroll",
                  start: "top bottom",
                  end: "bottom top",

                }
              })
              // Image Entrance 6
              gsap.set(".ifscroll6", { scale: 0, opacity: 0.2 });
              gsap.to('.ifscroll6', {
                opacity: 1,
                scale: 1,
                delay: 0.4,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".bo-home",
                  start: "top center",
                  end: "bottom top",

                }
              })
              // Image Entrance 7
              gsap.set(".ifscroll7", { scale: 0, opacity: 0.2 });
              gsap.to('.ifscroll7', {
                opacity: 1,
                scale: 1,
                delay: 0.5,
                ease: "easeOut",
                scrollTrigger: {
                  trigger: ".cl-slider",
                  start: "top center",
                  end: "bottom top",

                }
              })

              // bussiness opportunities
              gsap.set(".our-process-txt1", { y: "27vw", rotateZ:"5deg" });
              gsap.to(".our-process-txt1", {
                y: 0,
                rotateZ:"0deg",
                ease: "Power3.easeOut",
                duration: 0.7,
                scrollTrigger: {
                    trigger: ".our-process-txt1",
                    start: "top 90%"
                }
              });
              gsap.set(".our-process-txt2", { y: "27vw", rotateZ:"5deg", });
              gsap.to(".our-process-txt2", {
                y: 0,
                rotateZ:"0deg",
                ease: "Power3.easeOut",
                duration: 0.72,
                scrollTrigger: {
                    trigger: ".our-process-txt2",
                    start: "top 80%"
                }
              });
                
            }, 
        
            // all 
            "all": function() {

                // Home Hero Timeline
        
                gsap.set(".extra-button1", {opacity: 0, yPercent: 0});
                gsap.to(".extra-button1", {
                    delay: 1.4,
                    opacity: 1,
                    yPercent: 0,
                    duration: 0.7
                });
                gsap.set(".extra-button2", {opacity: 0, yPercent: 0});
                gsap.to(".extra-button2", {
                    delay: 1.7,
                    opacity: 1,
                    yPercent: 0,
                    duration: 0.7
                });
                gsap.set(".extra-button3", {opacity: 0, yPercent: 0});
                gsap.to(".extra-button3", {
                    delay: 2,
                    opacity: 1,
                    yPercent: 0,
                    duration: 0.7
                });
                gsap.set(".extra-button4", {opacity: 0, yPercent: 0});
                gsap.to(".extra-button4", {
                    delay: 2.3,
                    opacity: 1,
                    yPercent: 0,
                    duration: 0.7
                });
                
            }
        
        });
           
  
    // Image Entrance 3
    gsap.set(".ifscroll3", { scale: 0, opacity: 0.2 });
    gsap.to('.ifscroll3', {
      opacity: 1,
      scale: 1,
      ease: "easeOut",
      scrollTrigger: {
        trigger: ".aboutscroll",
        start: "top center",
        end: "bottom top",

      }
    })
  
    // Image Entrance 4
    gsap.set(".ifscroll4", { scale: 0, opacity: 0.2 });
    gsap.to('.ifscroll4', {
      opacity: 1,
      scale: 1,
      ease: "easeOut",
      scrollTrigger: {
        trigger: ".aboutscroll",
        start: "top center",
        end: "bottom top",

      }
    })

    // Image Float 3
    gsap.to('.ifscroll3', {
      yPercent: -150,
      xPercent: -10,
      ease: "easeOut",
      scrollTrigger: {
        trigger: ".aboutscroll",
        start: "top center",
        end: "bottom top",
        scrub: true
      }
    })

    // Image Float 4
    gsap.to('.ifscroll4', {
      yPercent: -190,
      xPercent: 40,
      ease: "easeOut",
      scrollTrigger: {
        trigger: ".aboutscroll",
        start: "top center",
        end: "bottom top",
        scrub: true
      }
    })

        gsap.set(".home-about .line span", {y: 180, rotateZ: 6});
        gsap.to(".home-about .line span", {
          y: 0,
          rotateZ: 0,
          ease: "Power3.easeOut",
          delay: 0,
          stagger: {
            amount: 0.15
          },
          scrollTrigger: {
            trigger: ".home-about .line span",
            start: "top 90%",
          }
        });

        gsap.set(".home-clients .line span", {y: 180, rotateZ: 6});
        gsap.to(".home-clients .line span", {
          y: 0,
          rotateZ: 0,
          ease: "Power3.easeOut",
          delay: 0,
          stagger: {
            amount: 0.15
          },
          scrollTrigger: {
            trigger: ".home-clients .line span",
            start: "top 80%",
          }
        });

        gsap.set(".home-what .line span", {y: 180, rotateZ: 6});
        gsap.to(".home-what .line span", {
          y: 0,
          rotateZ: 0,
          ease: "Power3.easeOut",
          delay: 0,
          duration: 1,
          scrollTrigger: {
            trigger: ".home-what .line span",
            start: "top 90%",
          }
        });
        gsap.set(".hw-mobile-h2 h2", {opacity: 0, yPercent: 20});
        gsap.to(".hw-mobile-h2 h2", {
            opacity: 1,
            yPercent: 0,
            scrollTrigger: {
                trigger: ".hw-mobile-h2 h2",
                start: "top 70%",
            }
        });
        gsap.set(".home-what a", {opacity: 0});
        gsap.to(".home-what a", {
          opacity: 1,
          ease: "Power3.easeOut",
          delay: 0.7,
          scrollTrigger: {
            trigger: ".home-what .line span",
            start: "top 90%",
          }
        });
        gsap.set(".home-about .link", {opacity: 0});
        gsap.to(".home-about .link", {
          opacity: 1,
          ease: "Power3.easeOut",
          delay: 1,
          scrollTrigger: {
            trigger: ".home-about .line span",
            start: "top 90%",
          }
        });
        gsap.set(".home-about-inner2-h2", { opacity: 0 });
        gsap.to(".home-about-inner2-h2", {
        opacity: 1, 
        ease: "Power3.easeOut",
        duration: 1,
        scrollTrigger: {
            trigger: ".home-about-inner2-h2",
            start: "top 60%",
        }
        });
        gsap.set(".home-about-inner2-line", { width: "0%" });
        gsap.to(".home-about-inner2-line", {
        width: '73.3%',
        ease: "Power3.easeOut",
        delay: .5,
        duration: 1,
        scrollTrigger: {
            trigger: ".home-about-inner2-line",
            start: "top 60%"
        }
        });
        gsap.set(".home-about-inner2-h3", { y: "24vw", rotateZ:"5deg" });
        gsap.to(".home-about-inner2-h3", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        delay: 1,
        scrollTrigger: {
            trigger: ".home-about-inner2-line",
            start: "top 60%"
        }
        });
        gsap.set(".home-about-inner2-h32", { y: "24vw", rotateZ:"5deg", });
        gsap.to(".home-about-inner2-h32", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        delay: 0.5,
        scrollTrigger: {
            trigger: ".home-about-inner2-h3",
            start: "top 60%"
        }
        });
        gsap.set(".mob-gra-text-1", { y: "24vw", rotateZ:"5deg" });
        gsap.to(".mob-gra-text-1", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        delay: 0.3,
        scrollTrigger: {
            trigger: ".mob-gra-text-1",
            start: "top 60%"
        }
        });
        gsap.set(".mob-gra-text-2", { y: "24vw", rotateZ:"5deg", });
        gsap.to(".mob-gra-text-2", {
        y: 0,
        rotateZ:"0deg",
        ease: "Power3.easeOut",
        delay: 0.6,
        scrollTrigger: {
            trigger: ".mob-gra-text-1",
            start: "top 60%"
        }
        });

        gsap.set(".fixed-work", { position: "relative" });
        gsap.to(".fixed-work", {
        position: "fixed",
        top: "10%",
        ease: "Power3.easeOut",
        scrollTrigger: {
            trigger: ".fixed-work",
            start: "1%",
            scrub: true,
        }
        });
        // gsap.set(".fixed-work", {  color: "#FFFFFF" });
        // gsap.to(".fixed-work", {
        // color: "#808080",
        // ease: "Power3.easeOut",
        // scrollTrigger: {
        //     trigger: ".fixed-work",
        //     start: "25%",
        //     scrub: true
        // }
        // });
        gsap.set(".fixed-work", {opacity: 1 });
        gsap.to(".fixed-work", {
          opacity: 0,
          ease: "Power3.easeOut",
          scrollTrigger: {
            trigger: ".work-hide",
            start: 'top 150%',
            scrub: true,
          }
        });
        gsap.set(".vd-col-row", { scale: 1.5 });
        gsap.to(".vd-col-row", {
          scale: 1,
          ease: "Power3.linear",
          scrollTrigger: {
            trigger: ".home-section2",
            duration: 1,
            start: 'top 95%'
          }
        });
        gsap.set(".video-col1", { yPercent: -15 });
        gsap.to(".video-col1", {
          yPercent: 0,
          ease: "Power3.easeOut",
          scrollTrigger: {
            trigger: ".home-section2",
            start: 'top 90%',
            scrub: true,
          }
        });
        gsap.set(".video-col3", { yPercent: -30 });
        gsap.to(".video-col3", {
          yPercent: 0,
          ease: "Power3.easeOut",
          scrollTrigger: {
            trigger: ".home-section2",
            start: 'top 90%',
            scrub: true,
          }
        });
        gsap.set(".video-col2", { yPercent: 15 });
        gsap.to(".video-col2", {
          yPercent: -15,
          ease: "Power3.easeOut",
          scrollTrigger: {
            trigger: ".home-section2",
            start: 'top 90%',
            scrub: true,
          }
        });
        // gsap.set(".home-section2", {visibility: "visible" });
        // gsap.to(".home-section2", {
        //   visibility: "hidden",
        //   ease: "Power3.easeOut",
        //   scrollTrigger: {
        //     trigger: ".home-infinity",
        //     start: 'top 40%',
        //     scrub: true,
        //   }
        // });
        gsap.set(".home-what-inner2-row", {width: 0 });
        gsap.to(".home-what-inner2-row", {
          width: "100%",
          ease: "Power3.easeOut",
          duration: 1,
          scrollTrigger: {
            trigger: ".home-what-inner2",
            start: 'top 80%',
          }
        });
        gsap.set(".lottie-up", {display: "none"});
        gsap.to(".lottie-up", {
          display: "block",
          ease: "Power3.easeOut",
          duration: 1,
          scrollTrigger: {
            trigger: ".home-what-inner2",
            start: 'top 70%',
          }
        });
        gsap.set(".home-infinity", {opacity: 1 });
        gsap.to(".home-infinity", {
          opacity: 0,
          ease: "Power3.easeOut",
          scrollTrigger: {
            trigger: ".home-what-inner1",
            end: 'top 25%',
            scrub: true,
          }
        });
        gsap.set(".home-infinity", {visibility: "hidden" });
        gsap.to(".home-infinity", {
          visibility: "visible",
          ease: "Power3.easeOut",
          scrollTrigger: {
            trigger: ".home-about",
            end: 'top 25%',
            scrub: true,
          }
        });
        gsap.set(".workanimation", { visibility: "hidden" });
        ScrollTrigger.batch(".workanimation", {
            onEnter: batch => {
                gsap.from(batch, {
                    duration: 1.5,
                    delay: 0,
                    yPercent: 50,
                    scale: 1,
                    autoAlpha: 0,
                    ease: "Power3.easeOut",
                    stagger: 0.4,
                    visibility: "visible",
                })
            },
            toggleActions: "play none none none",
            once: true,
            start: 'top 80%',
            end: 'top 40%',
            markers: false,
        });
        gsap.set(".workanimation1", { visibility: "hidden" });
        ScrollTrigger.batch(".workanimation1", {
            onEnter: batch => {
                gsap.from(batch, {
                    duration: 1,
                    delay: 0,
                    yPercent: 50,
                    scale: 1,
                    autoAlpha: 0,
                    ease: "Power3.easeOut",
                    stagger: 0.4,
                    visibility: "visible",
                })
            },
            toggleActions: "play none none none",
            once: true,
            start: 'top 80%',
            end: 'top 40%',
            markers: false,
        });
        gsap.set(".workanimation2", { visibility: "hidden" });
        ScrollTrigger.batch(".workanimation2", {
            onEnter: batch => {
                gsap.from(batch, {
                    duration: 1.5,
                    delay: 0.5,
                    yPercent: 50,
                    scale: 1,
                    autoAlpha: 0,
                    ease: "Power3.easeOut",
                    stagger: 0.4,
                    visibility: "visible",
                })
            },
            toggleActions: "play none none none",
            once: true,
            start: 'top 80%',
            end: 'top 40%',
            markers: false,
        });

        //Experimental
        gsap.set(".svg1", { visibility: "hidden" });
        ScrollTrigger.batch(".svg1", {
            onEnter: batch => {
                gsap.from(batch, {
                    duration: 1,
                    opacity: 1,
                    delay: 0,
                    yPercent: 100,
                    rotateZ: 5,
                    scale: 1,
                    autoAlpha: 0,
                    ease: "Power3.easeOut",
                    stagger: 0.08,
                    visibility: "visible",
                })
            },
            toggleActions: "play none none none",
            once: true,
            start: 'top 80%',
            end: 'top 40%',
            markers: false,
        });

        // gsap.set(".loadingh2 span", {translateY: "100%" });
        // gsap.to(".loadingh2 span", {
        //   translateY: "0%",
        //   delay: 0.1,
        //   duration: 1,
        //   ease: "Power3.easeIn",
        // });
        gsap.set(".loadingh2 span", { visibility: "hidden" });
        ScrollTrigger.batch(".loadingh2 span", {
            onEnter: batch => {
                gsap.from(batch, {
                    duration: 0.5,
                    delay: 0,
                    yPercent: 50,
                    scale: 1,
                    autoAlpha: 0,
                    ease: "Power3.easeOut",
                    stagger: 0.1,
                    visibility: "visible",
                })
            },
            toggleActions: "play none none none",
            once: true,
            start: 'top 80%',
            end: 'top 40%',
            markers: false,
        });


        
    }
    

    componentDidMount(){
        this.homeGsap();
    }

  render() {

    var sliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 5,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              arrows: false,
              slidesToScroll: 3,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: "20px",
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };

    return (
      <>
      <Header />

      {/* Home Hero Section */}
      <div className='home-hero'>
        <div className='container'>
            <div className='hhero-img'>
                <div className='d-none d-lg-block d-md-block'>
                  <img className='img-fluid extra-button-y' src={Hheroimg} alt="Just-Casuals" />
                </div>
                <div className='d-block d-lg-none d-md-none'>
                  <img className='img-fluid extra-button-y' src={Hheroimgresp} alt="Just-Casuals" />
                </div>
            </div>

            <div className="marquee1">
                <div className="marquee__inner-wrap">
                    <div className="marquee__inner extra-button-x">
                        <div className="marquee__img"></div>
                        <div className="marquee__img"></div>
                        <div className="marquee__img"></div>
                        <div className="marquee__img"></div>
                        <div aria-hidden="true" className="marquee__img"></div>
                        <div aria-hidden="true" className="marquee__img"></div>
                        <div aria-hidden="true" className="marquee__img"></div>
                        <div aria-hidden="true" className="marquee__img"></div>
                    </div>
                </div>
            </div>

            <div className='line-images'>
                <img className='img-fluid limg1 extra-button1' src={Line1} alt='Just-casuals' />
                <img className='img-fluid limg2 extra-button2 d-none d-lg-block' src={Line2} alt='Just-casuals' />
                <img className='img-fluid limg3 extra-button3' src={Line3} alt='Just-casuals' />
                <img className='img-fluid limg4 extra-button4 d-none d-lg-block' src={Line4} alt='Just-casuals' />
            </div>

        </div>

        <div className="marquee marquee-animate">
            <div className="track">
                <div className="content extra-button-x1">&nbsp;welcome fashionista * welcome fashionista * welcome fashionista * welcome fashionista * welcome fashionista * welcome fashionista * welcome fashionista * welcome fashionista *</div>
            </div>
        </div>
      </div>
      {/* Home Hero Section End */}

      {/* Home About Section */}
      <div className='about-home aboutscroll'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-8'>
                    <div className='about-home-cont home-clients'>
                        <img className='img-fluid haimg1 ifscroll5' src={HAimg1} alt='Just-casuals' />
                        <div className='about-home-contxt'>
                            <h2 className='workanimation1'>
                                <span>LOOK</span>
                            </h2>
                            <h2 className='workanimation1'>
                                <span>GREAT,</span>
                            </h2>
                            <h2 className='workanimation1'>
                                <span>FEEL</span>
                            </h2>
                            <h2 className='workanimation1'>
                                <span>BETTER.</span>
                            </h2>
                        </div>
                    </div>
                </div>
                            
                <div className='col-lg-4 position-relative d-flex'>
                    <img className='img-fluid haimg2' src={HAimg2} alt='Just-casuals' />
                    <img className='img-fluid haimg3 ifscroll1' src={HAimg3} alt='Just-casuals' />
                </div>

                <div className='col-lg-3 about-home-cont0'>
                    <img className='img-fluid haimg4 ifscroll2' src={HAimg4} alt='Just-casuals' />
                </div>
                <div className='col-lg-6'>
                    <div className='about-home-cont0 workanimation'>
                        <img className='img-fluid haimgarrow' src={HAarrow} alt='Just-casuals' />
                        <p>Our exciting range of surplus apparel includes fashion's coolest picks, including shorts, tees, capris, spaghettis, hoodies, denims, sheer tops and much more.</p>
                    </div>
                    <div className='h-about-anchor workanimation'>
                        <div className='h-about-line'></div>
                        <a className='h-about-anchor-txt' href='/about-us'>Our Story</a>
                    </div>
                </div>
                <div className='col-lg-3'></div>
                        
            </div>
            
        </div>
      </div>
      {/* Home About Section End */}

      {/* Section 3 */}
      <div className='bo-home'>
        <div className='container bo-home-cont'>
            <div className='position-relative'>
                <img className='img-fluid h-bo-img1 ifscroll6' src={Hboimg} alt='Just-casuals' />
                <div className='bo-home-cont-h2'>
                  <h2 className='our-process-txt1'>Business</h2>
                </div>
                <div className='bo-home-cont-h2'>
                  <h2 className='our-process-txt2'>Opportunities</h2>
                </div>
            </div>
            <p className='workanimation2'>Work with the fastest-growing surplus apparel retailer <br className='d-none d-lg-block' /> in India to run your own business while gaining access <br className='d-none d-lg-block' /> to our family-like support network.</p>
            <div className='row bo-home-anchor'>
                <div className='bo-home-line extra-button-x2'></div>
                <div className='col-lg-6'>
                    <div className='btn-svg'>
                        <a className='' href='/business-opportunities'>
                            <div className='lott-anim extra-button-x3'>
                                <Lottie 
                                    loop
                                    animationData={Arrowjson1}
                                    play
                                />
                            </div>
                            {/* <video src={Arrowvdo} muted autoPlay={"autoplay"} preload="auto" loop playsInline controlsList="nodownload" poster="./../assets/img/bo-img-arrow.png"/> */}
                            {/* <img className='img-fluid' src={BOarrow} alt='Just-casuals' /> */}
                            <p className='workanimation2'>Explore <br />Franchise</p>
                        </a>
                    </div>
                </div>
                <div className='col-lg-6'>
                    <div className='btn-svg'>
                        <a className='' href='/excess-inventory'>
                            <div className='lott-anim extra-button-x4'>
                                <Lottie 
                                    loop
                                    animationData={Arrowjson1}
                                    play
                                />
                            </div>
                            {/* <img className='img-fluid' src={BOarrow1} alt='Just-casuals' /> */}
                            {/* <svg width="177" height="146" viewBox="0 0 177 146" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 87V58.5H116C92.8 40.5 81.3333 12.6667 78.5 1H103C107 1 109.333 3.66667 110 5C114.4 15.8 126.5 29.8333 132 35.5C143.2 46.3 162 57 170 61C175.2 63.4 175.5 70.6667 175 74C175 81.2 171.667 84.3333 170 85L162 89C129.6 106.2 113.833 130.833 110 141C110 143 105.667 144.5 103.5 145H79C82.2 120.2 107.333 96 119.5 87H1Z" stroke="white" stroke-width="2"/>
                            </svg> */}
                            <p className='workanimation2'>Sell <br />to us</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
      </div>
      {/* Section 3 End */}

      {/* Section 4 */}
      <div className='feofe-home'>
        <div className='row'>
            <div className='col-lg-6 p-0'>
                <img className='img-fluid' src={Feofeimg} alt='Just-casuals' />
            </div>
            <div className='col-lg-6 feofe-home-cont p-0'>
                <h4 className='workanimation1 d-none d-lg-block'>
                  <span className=''>for every</span>
                </h4>
                <h4 className='workanimation1 d-none d-lg-block'>
                  <span className='workanimation1'>occasion,</span> 
                </h4>
                <h4 className='workanimation1 d-none d-lg-block'>
                  <span className='workanimation1'>for everyone.</span>
                </h4>
                <h4 className='workanimation d-block d-lg-none'>for every <br /> occasion, <br /> for everyone.</h4>
                <p className='workanimation'>Just Casuals is a retail brand that <br className='d-none d-lg-block' /> operates multiple stores across <br className='d-none d-lg-block' /> various cities. In total, the brand <br className='d-none d-lg-block' /> has 24 stores located in 9 cities <br className='d-none d-lg-block' /> across the country.</p>
                <a className='' href='/find-a-store'>
                    <p className='workanimation'>Find a store near you</p>
                    <div className='feofe-line extra-button-x5'></div>
                </a>
            </div>
        </div>
      </div>
      {/* Section 4 End */}

      {/* Section 5 */}
      <div className='cl-slider'>
        <div className='cl-slide extra-button-x6'>
            <Slider {...sliderSettings}>
                <div className='home-client-logo ifscroll7'>
                    <img className='img-fluid' src={Slideimg1} alt='Just-casuals' />
                </div>
                <div className='home-client-logo ifscroll7'>
                    <img className='img-fluid' src={Slideimg2} alt='Just-casuals' />
                </div>
                <div className='home-client-logo ifscroll7'>
                    <img className='img-fluid' src={Slideimg3} alt='Just-casuals' />
                </div>
                <div className='home-client-logo ifscroll7'>
                    <img className='img-fluid' src={Slideimg4} alt='Just-casuals' />
                </div>
                <div className='home-client-logo ifscroll7'>
                    <img className='img-fluid' src={Slideimg5} alt='Just-casuals' />
                </div>
                <div className='home-client-logo ifscroll7'>
                    <img className='img-fluid' src={Slideimg1} alt='Just-casuals' />
                </div>
                <div className='home-client-logo ifscroll7'>
                    <img className='img-fluid' src={Slideimg2} alt='Just-casuals' />
                </div>
                <div className='home-client-logo ifscroll7'>
                    <img className='img-fluid' src={Slideimg3} alt='Just-casuals' />
                </div>
                <div className='home-client-logo ifscroll7'>
                    <img className='img-fluid' src={Slideimg4} alt='Just-casuals' />
                </div>
                <div className='home-client-logo ifscroll7'>
                    <img className='img-fluid' src={Slideimg5} alt='Just-casuals' />
                </div>
            </Slider>
        </div>
      </div>
      {/* Section 5 End */}


      <footer>
        <div className='container workanimation'>
          <div>
            <InstaFeed />
          </div>
          <h4><a className='fuoi-txt' href='https://www.instagram.com/justcasuals/' target='_blank'>Follow us on Instagram </a></h4>
          <div className='footer-border'></div>
          <div className='footer-menu'>
            <ul className="foot-menu">
              <li><a href="/about-us">About</a></li>
              <li><a href="/business-opportunities">Business Opportunities</a></li>
              <li><a href="/excess-inventory">Excess Inventory</a></li>
              <li><a href="/find-a-store">Find a Store</a></li>
              <li><a href="/" className='modal-buu' data-bs-toggle="modal" data-bs-target="#exampleModal">Contact</a></li>
            </ul>
          </div>
          <div className='footer-c'>
            <h5>Copyright © 2023 Just Casuals. <br className='d-block d-lg-none d-md-none' /> All rights reserved</h5>
            <a target="_blank" href='https://fingerprintscreative.com'>Made by Fingerprints Creative</a>
          </div>
        </div>
      </footer>
        
      </>
    )
  }
}
