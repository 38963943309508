import React, { Component, useEffect, useState, useRef } from "react";
import axios, * as others from 'axios';
import { useForm } from "react-hook-form";
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Lottie from 'lottie-react'
import Slider from 'react-slick'
import Footer from '../components/footer'
import Header from '../components/header'
import EIimg3 from './../assets/img/ei-hero-img3.png'
import EIimg4 from './../assets/img/ei-hero-img4.png'
import EIresp from './../assets/img/ei-hero-img4-resp.png'
import EIimg5 from './../assets/img/ei-hero-img5.svg'
import EIimg8 from './../assets/img/ei-hero-img8-1.svg'
import BoS3Mid from './../assets/img/bos3mid.svg'
import EIjson1 from './../assets/img/jc-ei-json1.json'
import EIjson2 from './../assets/img/jc-ei-json2.json'
import EIjson3 from './../assets/img/jc-ei-json3.json'
import EIjson4 from './../assets/img/jc-ei-json4.json'
import EIhero1 from './../assets/img/ei-hero-img-c-1.png'
import EIhero2 from './../assets/img/ei-hero-img-c-2.png'
import EIhero3 from './../assets/img/ei-hero-img-c-3.png'
import swal from "sweetalert";
import Api from "../../package.json";
import { FileUploader } from "react-drag-drop-files";
const fileTypes = ["JPG", "PNG", "GIF"];


const ExcessInventory = (props) => {

  const {
    register,
    handleSubmit, setValue,
    formState: { errors }, getValues,
  } = useForm();
  const buttonRef = useRef();
  const [SubmitButton, setSubmitButton] = useState("Submit");
  const [pictureFile,setpictureFile] = useState("");

  const businessoppGsap = () => {

    gsap.registerPlugin(ScrollTrigger)

    ScrollTrigger.saveStyles(".mobile, .desktop");

    ScrollTrigger.matchMedia({

      // desktop
      "(min-width: 1024px)": function () {

        // Image Entrance 1
        gsap.set(".ifscroll1", { scale: 0, opacity: 0.2 });
        gsap.to('.ifscroll1', {
          opacity: 1,
          scale: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".aboutscroll",
            start: "top center",
            end: "bottom top",
          }
        })

        // Image Entrance 2
        gsap.set(".ifscroll2", { scale: 0, opacity: 0.2 });
        gsap.to('.ifscroll2', {
          opacity: 1,
          scale: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".aboutscroll",
            start: "top center",
            end: "bottom top",

          }
        })

        // Image Entrance 3
        gsap.set(".ifscroll3", { scale: 0, opacity: 0.2 });
        gsap.to('.ifscroll3', {
          opacity: 1,
          scale: 1,
          delay: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".about-rt",
            start: "top center",
            end: "bottom top",

          }
        })

        // Image Entrance 4
        gsap.set(".ifscroll4", { scale: 0, opacity: 0.2 });
        gsap.to('.ifscroll4', {
          opacity: 1,
          scale: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".about-rt",
            start: "top center",
            end: "bottom top",

          }
        })

        // Image Entrance 5
        gsap.set(".ifscroll5", { scale: 0, opacity: 0 });
        gsap.to('.ifscroll5', {
          opacity: 1,
          scale: 1,
          delay: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".about-rt",
            start: "top center",
            end: "bottom top",

          }
        })

        // Image Entrance json1
        gsap.set(".jsonei1", { scale: 0, opacity: 0 });
        gsap.to('.jsonei1', {
          opacity: 1,
          scale: 1,
          delay: 0.5,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".element1",
            start: "top center",
            end: "bottom top",

          }
        })
        // Image Entrance json
        gsap.set(".jsonei2", { scale: 1, opacity: 0 });
        gsap.to('.jsonei2', {
          opacity: 1,
          scale: 1,
          delay: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".elements2",
            start: "top bottom",
            end: "bottom top",

          }
        })

        // Image Entrance json
        gsap.set(".jsonei3", { scale: 0, opacity: 0 });
        gsap.to('.jsonei3', {
          opacity: 1,
          scale: 1,
          delay: 0.5,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".ei-s2-content",
            start: "top center",
            end: "bottom top",

          }
        })

        // Image Entrance json3
        gsap.set(".jsonei4", { scale: 0, opacity: 0 });
        gsap.to('.jsonei4', {
          opacity: 1,
          scale: 1,
          delay: 0.8,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".ei-s2-content",
            start: "top center",
            end: "bottom top",

          }
        })

        // Image Entrance Img
        gsap.set(".bo-imgstc img", { scale: 0, opacity: 0 });
        gsap.to('.bo-imgstc img', {
          opacity: 1,
          scale: 1,
          delay: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".bo-hero",
            start: "top center",
            end: "bottom top",

          }
        })



        // Image Float 1
        gsap.to('.ifscroll1', {
          yPercent: -20,
          xPercent: -10,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".aboutscroll",
            start: "top center",
            end: "bottom top",
            scrub: true
          }
        })

        // Image Float 2
        gsap.to('.ifscroll2', {
          yPercent: -20,
          xPercent: -20,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".aboutscroll",
            start: "top center",
            end: "bottom top",
            scrub: true
          }
        })

        // Image Float 3
        gsap.to('.ifscroll3', {
          yPercent: -20,
          xPercent: -10,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".about-rt",
            start: "top center",
            end: "bottom top",
            scrub: true
          }
        })

        // Image Float 4
        gsap.to('.ifscroll4', {
          yPercent: 10,
          xPercent: 30,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".about-rt",
            start: "top center",
            end: "bottom top",
            scrub: true
          }
        })

        gsap.set(".owpimg-desktop2", { opacity: 0 });
        gsap.to(".owpimg-desktop2", {
          opacity: 1,
          duration: 1,
          delay: 1.2,
          ease: "Power3.easeOut",
          scrollTrigger: {
            trigger: ".owpimg-desktop2",
            start: 'top 60%'
          }
        });
      },

      // mobile
      "(max-width: 799px)": function () {


        // Image Entrance 1
        gsap.set(".ifscroll1", { scale: 0, opacity: 0.2 });
        gsap.to('.ifscroll1', {
          opacity: 1,
          scale: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".aboutscroll",
            start: "top center",
            end: "bottom top",
          }
        })
        // Image Float 1
        gsap.to('.ifscroll1', {
          yPercent: -30,
          xPercent: -10,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".aboutscroll",
            start: "top center",
            end: "bottom top",
            scrub: true
          }
        })

        // Image Entrance 2
        gsap.set(".ifscroll2", { scale: 0, opacity: 0.2 });
        gsap.to('.ifscroll2', {
          opacity: 1,
          scale: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".aboutscroll",
            start: "top center",
            end: "bottom top",

          }
        })
        // Image Float 2
        gsap.to('.ifscroll2', {
          yPercent: -20,
          xPercent: -10,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".aboutscroll",
            start: "top center",
            end: "bottom top",
            scrub: true
          }
        })

        // Image Entrance 5
        gsap.set(".ifscroll5", { scale: 0, opacity: 0.2 });
        gsap.to('.ifscroll5', {
          opacity: 1,
          scale: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".aboutscroll",
            start: "top top",
            end: "bottom top",

          }
        })
        // Image Entrance 6
        gsap.set(".ifscroll6", { scale: 0, opacity: 0.2 });
        gsap.to('.ifscroll6', {
          opacity: 1,
          scale: 1,
          delay: 0.8,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".bo-home",
            start: "top center",
            end: "bottom top",

          }
        })
        // Image Entrance 7
        gsap.set(".ifscroll7", { scale: 0, opacity: 0.2 });
        gsap.to('.ifscroll7', {
          opacity: 1,
          scale: 1,
          delay: 0.5,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".cl-slider",
            start: "top center",
            end: "bottom top",

          }
        })
        // Image Entrance json1
        gsap.set(".jsonei1", { scale: 0, opacity: 0 });
        gsap.to('.jsonei1', {
          opacity: 1,
          scale: 1,
          delay: 0.7,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".element1",
            start: "top center",
            end: "bottom top",

          }
        })
        // Image Entrance json
        gsap.set(".jsonei2", { scale: 1, opacity: 0 });
        gsap.to('.jsonei2', {
          opacity: 1,
          scale: 1,
          delay: 0.5,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".elements2",
            start: "top bottom",
            end: "bottom top",

          }
        })
        gsap.set(".owpimg-desktop2", { opacity: 0 });
        gsap.to(".owpimg-desktop2", {
          opacity: 1,
          duration: 1,
          delay: 1,
          ease: "Power3.easeOut",
          scrollTrigger: {
            trigger: ".owpimg-desktop2",
            start: 'top 80%'
          }
        });

      },

      // all 
      "all": function () {
      }

    });

    gsap.set(".workanimation", { visibility: "hidden" });
    ScrollTrigger.batch(".workanimation", {
      onEnter: batch => {
        gsap.from(batch, {
          duration: 1,
          delay: 0.5,
          yPercent: 50,
          scale: 1,
          autoAlpha: 0,
          ease: "Power3.easeOut",
          stagger: 0.4,
          visibility: "visible",
        })
      },
      toggleActions: "play none none none",
      once: true,
      start: 'top 80%',
      end: 'top 40%',
      markers: false,
    });
    gsap.set(".workanimation1", { visibility: "hidden" });
    ScrollTrigger.batch(".workanimation1", {
      onEnter: batch => {
        gsap.from(batch, {
          duration: 1,
          delay: 0.5,
          yPercent: 50,
          scale: 1,
          autoAlpha: 0,
          ease: "Power3.easeOut",
          stagger: 0.4,
          visibility: "visible",
        })
      },
      toggleActions: "play none none none",
      once: true,
      start: 'top 70%',
      end: 'top 40%',
      markers: false,
    });

    //ProcessIMG 
    gsap.set(".cp-image-reveal", { opacity: 0 });
    gsap.to(".cp-image-reveal", {
      opacity: 1,
      ease: "Power3.easeOut",
      delay: 1.5,
      duration: 1
    });

    //Our APPROACH
    gsap.set(".process-approach .line h1", { y: 180, rotateZ: 6 });
    gsap.to(".process-approach .line h1", {
      y: 0,
      rotateZ: 0,
      ease: "Power3.easeOut",
      delay: 0,
      stagger: {
        amount: 0.2
      },
      scrollTrigger: {
        trigger: ".process-approach .line h1",
        start: "top 90%",
      }
    });

    //Our APPROACH
    gsap.set(".bo-s2-content .line span", { y: 180, rotateZ: 6 });
    gsap.to(".bo-s2-content .line span", {
      y: 0,
      rotateZ: 0,
      ease: "Power3.easeOut",
      delay: 0,
      stagger: {
        amount: 0.2
      },
      scrollTrigger: {
        trigger: ".bo-s2-content .line span",
        start: "top 90%",
      }
    });

    gsap.set(".owpimg-desktop1", { opacity: 0 });
    gsap.to(".owpimg-desktop1", {
      opacity: 1,
      duration: 1,
      delay: 0.8,
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: ".owpimg-desktop1",
        start: 'top 70%'
      }
    });

    // gsap.set(".bo-s3-top", {opacity: 0, xPercent: -100});
    // gsap.to(".bo-s3-top", {
    //     delay: 1.5,
    //     opacity: 1,
    //     xPercent: 0,
    //     duration: 1,
    //     scrollTrigger: {
    //         trigger: ".bo-s3-content",
    //         start: "top center",
    //         end: "bottom top",
    //     }
    // });

  }
/*   const headers = {
    type: "application/json",
    Authorization: `Bearer ` + window.localStorage.getItem('access_token'),
  }; */
  
  useEffect(() => {
    businessoppGsap();
  }, []);
  const onSubmit = (data) => {
    if (fill_val_addition_doc() == true) {
      buttonRef.current.disabled = true;
      setSubmitButton("submitting...");
      get_inventory(data);
    }
  };
    const fill_val_addition_doc = () => {
      var picture = getValues(`picture`);
      var picture_text = " Selected Files : ";
  
      var valid_file = true;
      if(picture == undefined )
      {
        valid_file = false;
        picture_text = "Nothing selected";
      }
      else if ( picture.length <= 5 && picture.length > 0) {
  
        for (var k = 0; k < picture.length; k++) {
          if (picture.length - 1 == k) {
            picture_text += picture[k]['name'];
          }
          else {
            picture_text += picture[k]['name'] + ",";
          }
  
        }
  
      }
      else if (picture.length >= 5) {
        valid_file = false;
        picture_text = "Maximum 5 files only allowed";
      }
      else {
        valid_file = false;
        picture_text = "Nothing selected";
      }
      setValue(`picture_text`, picture_text);
      return valid_file;
    };
  const get_inventory = async (data) => {
    const bodyFormData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key == 'picture') {
        for (var i = 0; i < data[key].length; i++) {

          bodyFormData.append('picture[' + i + ']', data[key][i]);

        }
      }
    });
    bodyFormData.append("available", data.available);
    bodyFormData.append("colors", data.colors);
    bodyFormData.append("merchandise", data.merchandise);
    bodyFormData.append("price", data.price);
    bodyFormData.append("yourname", data.yourname);
    bodyFormData.append("phone_number", data.phone_number);
    bodyFormData.append("email", data.email);
    bodyFormData.append("picture", data.picture);
    bodyFormData.append("action", 'Excess_inventory');
    const response = await axios({
      method: "post",
      url: Api.userdata.url + "/custom_api.php",
      data: bodyFormData,
    });
    const json = await response.data;
    if (json["status"] == "success") {
      swal("Thanks so much for reaching out to us!", json["status"], "success").then((value) => {
        window.location.reload(true);
      });
    } else {
      swal("Error !", json["status"], "error");
    }



    buttonRef.current.disabled = false;
    setSubmitButton("Submit");

  }
  {

    var sliderSettings = {
      draggable: true,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
      dots: false,
      fade: true,
      speed: 500,
      infinite: true,
      cssEase: 'ease-in-out',
      touchThreshold: 100,
      pauseOnHover: false
    };

    return (
      <>
        <Header />

        <div className='excess-inventor'>

          {/* Hero Section */}
          <div className='ei-hero'>
            <div className='container '>
              <div className='bo-hero-content-1 process-approach'>
                <div className='row'>
                  <div className='col-lg-7'>
                    <h2 className='d-none d-lg-block d-md-block workanimation'>Sell to us</h2>
                    <div className='line'>
                      <h1>Excess</h1>
                    </div>
                    <div className='line'>
                      <h1 className='h1before'>inventory&nbsp;</h1>
                    </div>
                  </div>
                  <div className='col-lg-1'></div>
                  <div className='col-lg-4 elements1'>
                    <div className='ei-lott1 jsonei1'>
                      <Lottie
                        loop
                        animationData={EIjson1}
                        play
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='ei-hero-content-2'>
                <div className='row'>
                  <div className='col-lg-3 elements2'>
                    <div className='ei-lott2 jsonei2'>
                      <Lottie
                        loop
                        animationData={EIjson2}
                        play
                      />
                    </div>
                  </div>
                  <div className='col-lg-7'>
                    <h3 className='workanimation1'>Our buying is round the year for surplus, excess inventory, cancelled shipments, out of season stocks, out of color or out of size ratio stocks.</h3>
                  </div>
                  <div className='col-lg-2'></div>
                </div>
                <div className='ei-hero-img3-4'>
                  <div className='ei-hero-bg'></div>
                  <img className='img-fluid ei-img3 owpimg-desktop2' src={EIimg3} alt='Just-casuals' />
                  <div className='slideshow'>
                    <Slider {...sliderSettings}>
                      <div className='item'>
                        <img className='img-fluid' src={EIhero1} alt='Just-casuals' />
                      </div>
                      <div className='item'>
                        <img className='img-fluid' src={EIhero2} alt='Just-casuals' />
                      </div>
                      <div className='item'>
                        <img className='img-fluid' src={EIhero3} alt='Just-casuals' />
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Section2 */}
          <div className='ei-s2'>
            <img src={EIimg5} className="ei-img5" alt='Just-casuals' />
            <div className='container ei-s2-content'>
              <div className='row resp-flx-dr'>
                <div className='col-lg-6'>
                  <div className='bo-s2-image-wrap'>
                    <div className='ei-lott3 jsonei3 '>
                      <Lottie
                        loop
                        animationData={EIjson3}
                        play
                      />
                    </div>
                    {/* <img src={EIimg6} className="ei-img6" alt='Just-casuals' /> */}
                  </div>
                </div>
                <div className='col-lg-1'></div>
                <div className='col-lg-5 align-self-center'>
                  <div className='ei-s2-cont'>
                    <div className='ei-lott4 jsonei4'>
                      <Lottie
                        loop
                        animationData={EIjson4}
                        play
                      />
                    </div>
                    {/* <img src={EIimg7} className="ei-img7" alt='Just-casuals' /> */}
                    <h3 className='workanimation'>We buy knits and woven apparel</h3>
                    <p className='workanimation'>Infants, kids, teenage, women's and men's apparel made for any season - Spring, summer or winter.</p>
                  </div>
                </div>
              </div>
            </div>
            <img src={EIimg8} className="ei-img8 owpimg-desktop1" alt='Just-casuals' />
          </div>

          {/* Section3 */}
          <div className='ei-s3'>
            <div className='container bo-s3-content'>
              <h3 className='workanimation'>
               If you have inventory to sell please write <br className='d-none d-lg-block' />to us with the following details and <br className='d-none d-lg-block' />we will get back to you soon.
              </h3>
              <img src={BoS3Mid} className="img-fluid bo-s3-top workanimation" alt='Just-casuals' />

              <form onSubmit={handleSubmit(onSubmit)} className='forms'   autoComplete="on"
                method="POST">
                <div className='bo-forms'>
                  <fieldset className='bo-forms-spc workanimation'>
                    <label>Quantity Available</label>
                    <input className='fs-inpt' type='text' name='available' placeholder='Please Enter the Quantity'  
                    {...register("available", {
                        required: {
                          value: true,
                          message: "Please enter the quantity available",
                        },minLength: {
                          value: 1,
                          message: "Invalid price",
                        },
                        maxLength: {
                          value: 15,
                          message: "Invalid price",
                        },
                        pattern: {
                          value: /^-?[0-9]\d*\.?\d*$/i,
                          message: "Invalid",
                        },
                      })} /> {errors.available && (
                        <span className="errors">{errors.available.message}</span>
                      )}
                  </fieldset>
                  <fieldset className='bo-forms-spc workanimation'>
                    <label>Colors</label>
                    <input className='fs-inpt' type='text' name='colors' placeholder='Please Enter the Colors Available'    
                    {...register("colors", {
                        required: {
                          value: true,
                          message: "Please enter the colors available",
                        },
                      })} /> {errors.colors && (
                        <span className="errors">{errors.colors.message}</span>
                      )}
                  </fieldset>
                </div>

                <div className='bo-forms'>
                  <fieldset className='bo-forms-spc workanimation'>
                    <label>Location of Merchandise</label>
                    <input className='fs-inpt' type='text' name='merchandise' placeholder='Please Enter the location or store name'    
                    {...register("merchandise", {
                        required: {
                          value: true,
                          message: "Please enter the location",
                        },
                      })} /> {errors.merchandise && (
                        <span className="errors">{errors.merchandise.message}</span>
                      )}
                  </fieldset>
                  <fieldset className='bo-forms-spc workanimation'>
                    <label>Price</label>
                    <input className='fs-inpt' type='text' name='price' placeholder='Please Enter the Price'  
                    {...register("price", {
                        required: {
                          value: true,
                          message: "Please enter the price",
                        },  minLength: {
                          value: 1,
                          message: "Invalid price",
                        },
                        maxLength: {
                          value: 15,
                          message: "Invalid price",
                        },
                        pattern: {
                          value: /^-?[0-9]\d*\.?\d*$/i,
                          message: "Invalid",
                        },
                      })} /> {errors.price && (
                        <span className="errors">{errors.price.message}</span>
                      )}
                  </fieldset>
                </div>

                <div className='bo-forms'>
                  <fieldset className='bo-forms-spc workanimation'>
                    <label>Your Name</label>
                    <input className='fs-inpt' type='text' name='yourname' placeholder='Please Enter Your Name'    
                    {...register("yourname", {
                        required: {
                          value: true,
                          message: "Please enter your name",
                        },
                      })} /> {errors.yourname && (
                        <span className="errors">{errors.yourname.message}</span>
                      )}
                  </fieldset>
                  <fieldset className='bo-forms-spc workanimation'>
                    <label>Your Phone Number</label>
                    <input className='fs-inpt' type='text' name='phone_number' placeholder='Please Enter Your Phone Number'   
                    {...register("phone_number", {
                        required: {
                          value: true,
                          message: "Please enter your phone number",
                        },  minLength: {
                          value: 6,
                          message: "Invalid phone number",
                        },
                        maxLength: {
                          value: 15,
                          message: "Invalid phone number",
                        },
                        pattern: {
                          value: /^-?[0-9]\d*\.?\d*$/i,
                          message: "Invalid",
                        },
                      })} /> {errors.phone_number && (
                        <span className="errors">{errors.phone_number.message}</span>
                      )}
                  </fieldset>
                </div>

                <div className='bo-forms'>
                  <fieldset className='bo-forms-spc workanimation'>
                    <label>Your Email</label>
                    <input className='fs-inpt' type='text' name='email' placeholder='Please enter your email address'  
                    {...register("email", {
                        required: {
                          value: true,
                          message: "Please enter your email address",
                        },
                         pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",}
                      })} /> {errors.email && (
                        <span className="errors">{errors.email.message}</span>
                      )}
                  </fieldset>
                  <fieldset className='bo-forms-spc workanimation'>
                    <h5>Upload Photos</h5>
                    <ul>
                      <li>Photographs of the surplus stocks</li>
                    </ul>
                    <FileUploader
                        {...register(`picture`)}
                        multiple={true}
                        types={fileTypes}
                        maxSize={5}
                        width={100}
                        name={`picture`}
                        id={`picture`}
                        handleChange={(val) => {
                          setValue(`picture`, val);
                          fill_val_addition_doc();
                        }}

                        label="Additional Documents allowed only * (pdf,png,jpeg) - Size(2M)"
                        className={` form-control ${errors.picture ? "is-invalid" : ""}`}

                      />
                      <div className="errors">
                        {errors.picture?.message}
                      </div>
                      <input style={{ backgroundColor: `#fffdf9`, opacity: `1`, border: `0` }}
                        {...register(`picture_text`)}
                        disabled
                        id={`picture_text`}
                        type="text"
                        name={`picture_text`}
                        placeholder=""
                        className={`form-control`}

                      />

                  </fieldset>
                </div>

                <div className='bo-forms workanimation'>
                  <div className='bo-forms-spc'></div>
                  <div className='bo-forms-spc'>
                    <button
                      className='submit-btn'
                      id="submitDetails"
                      name="my-buttons"
                      ref={buttonRef}
                      type="submit"
                    >
                     {SubmitButton}
                    </button>
                  </div>
                </div>
              </form>


            </div>
          </div>
        </div>

        <Footer />
      </>
    )
  }
}
export default ExcessInventory
