import React, { Component } from 'react'
import $ from "jquery";
import Footer from '../components/footer'
import Header from '../components/header'
import AboutHeroBottomD from './../assets/img/about-hero-bottom-d.svg'

export default class Store_Locator extends Component {
  render() {

    $(document).on('click', 'li', function(){
        $('li').removeClass('active');
        $('ul').toggleClass('expanded');
        $(this).addClass('active');
        var tab_id = $(this).attr('data-tab');
        $('.tab-content').removeClass('current');
        $(this).addClass('current');
        $('#'+tab_id).addClass('current');
      });
      
    return (
      <>
      <Header />

        <div className='store-locator'>

            {/* ST Hero Section */}
            <div className='st-hero about-hero'>
                <div className='container process-approach'>
                    <div className='about-hero-b-cont d-none d-lg-block d-md-block'>
                      <h1 className='line'>
                        <span>Store <br className='d-block d-lg-none d-md-none' /> <span className='purpose'>Locator</span></span>
                      </h1>
                    </div>
                    <div className='about-hero-b-cont d-block d-lg-none d-md-none'>
                      <h1 className='line'>
                        <span>Store </span>
                      </h1>
                      <h1 className='line'>
                        <span className='purpose'>Locator &nbsp;</span>
                      </h1>
                    </div>
                </div>
                {/* <img src={AboutHeroFloat} className="about-hero-float" alt='Just-casuals' /> */}
                <img src={AboutHeroBottomD} className="about-hero-bottomd" alt='Just-casuals' />
            </div>
            {/* ST Hero Section End */}

            

            {/* ST Tabs */}
            <div className='st-tabs container'>
                <div className="tabs-container">
                    <nav className="tabs">
                        <ul>
                            <li className="active" data-tab="tab-1">Chennai</li>
                            <li data-tab="tab-2">Mangalore</li>
                            <li data-tab="tab-3">Pune</li>
                            <li data-tab="tab-4">Goa</li>
                            <li data-tab="tab-5">Kolkata</li>
                            <li data-tab="tab-6">Kanpur</li>
                            <li data-tab="tab-7">Siliguri</li>
                            <li data-tab="tab-8">Surat</li>
                            <li data-tab="tab-9">Gangtok</li>
                        </ul>
                    </nav>
                </div>

                <div id="tab-1" className="tab-content current">
                    <div className='row'>
                        <div className='col-lg-4 col-md-4'>
                            <h4 className=''>Just Casuals, Sterling Road</h4>
                            <p className=''>61, Sterling Rd, Seetha Nagar, <br className='d-none d-lg-block' /> Nungambakkam, Chennai, Tamil Nadu 600034</p>
                            <a className='' href='tel:04442123882'>04442123882</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//Just+Casuals,+61,+Sterling+Rd,+Seetha+Nagar,+Nungambakkam,+Chennai,+Tamil+Nadu+600034/@13.0628787,80.2303479,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3a526663de8baffd:0x5efb03b029b5baa3!2m2!1d80.2361138!2d13.0638646' target='_Blank'><p>Get Direction</p></a>
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            <h4 className=''>Just Casuals, Besant Nagar</h4>
                            <p className=''>30, 4th Main Rd, Tiruvalluvar Nagar, <br className='d-none d-lg-block' /> Besant Nagar, Chennai, Tamil Nadu 600090</p>
                            <a className='' href='tel:04442033882'>04442033882</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.co.in/maps/dir//T53+A1+,+2nd+Floor,+Just+Casuals,+4th+Main+Rd,+Besant+Nagar,+Chennai,+Tamil+Nadu+600090/@13.0009345,80.2692636,15z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3a5267fb17d3c9cb:0x73f52bdb8275be97!2m2!1d80.2692636!2d13.0009345' target='_Blank'><p>Get Direction</p></a>
                        </div>
                        <div className='col-lg-4 col-md-4'></div>

                        <div className='st-map'>
                            <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1AD7CMiRSwvArTk6qd6P5kDW84cqLRt8&ehbc=2E312F" width="100%" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
                <div id="tab-2" className="tab-content">
                    <div className='row'>
                        <div className='col-lg-4 col-md-4'>
                            <h4 className=''>Just Casuals, Mangaluru</h4>
                            <p className=''>VR9X+5Q5, Mother Theresa Rd, Bendoor, <br className='d-none d-lg-block' />  Mangaluru, Karnataka 575002</p>
                            <a className='' href='tel:08242434707'>08242434707</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//VR9X%2B5Q5+Just+Casuals,+Mother+Theresa+Rd,+Bendoor,+Mangaluru,+Karnataka+575002/@12.8678905,74.849481,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3ba35a4a8c653739:0x842888b6242bfa47!2m2!1d74.8494804!2d12.8679185?hl=en-IN' target='_Blank'><p>Get Direction</p></a>
                        </div>

                        <div className='st-map'>
                            <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1NvKwwvez0lWiU8lCpYtJ7lzDoAbzTAk&ehbc=2E312F" width="100%" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
                <div id="tab-3" className="tab-content pune">
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 pune-add'>
                            <h4 className=''>Just Casuals, Bhandarkar Road</h4>
                            <p className=''>Shop # B-6, Dr. Herekar Park,Opposite To Kamla Nehru Park, Bhandarkar Rd, Shreeman Society, Deccan Gymkhana, Pune, Maharashtra 411004</p>
                            <a className='' href='tel:+91 77700 18501'>+91 77700 18501</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//Just+Casuals,+Shop+%23+B-6,+Dr.+Herekar+Park,Opposite+To+Kamla+Nehru+Park,+Bhandarkar+Rd,+Pune,+Maharashtra+411004/@18.5176617,73.8344707,17z/data=!3m1!5s0x3bc2bff326d82843:0x118ac97cffc4198!4m8!4m7!1m0!1m5!1m1!1s0x3bc2bf85952927cb:0x446c5c5049065f4c!2m2!1d73.8344707!2d18.5176617' target='_Blank'><p>Get Direction</p></a>
                        </div>

                        <div className='col-lg-4 col-md-4 pune-add'>
                            <h4 className=''>Just Casuals, Camp</h4>
                            <p className=''>Shop # 13, Tulsidas Apts., Dastur Meher Road, Camp, Gawaliwada, Modi Colony, Pune, Maharashtra 411001</p>
                            <a className='' href='tel:+91 77700 18502'>+91 77700 18502</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//Just+Casuals,+Shop+%23+13,+Tulsidas+Apts.,+Dastur+Meher+Road,+Camp,+Pune,+Maharashtra+411001/@18.5183057,73.8767065,17z/data=!3m1!5s0x3bc2c04f89f309f9:0x7b24b1e6f7952904!4m16!1m7!3m6!1s0x3bc2c045665b6e4f:0x95de9e8b4dcf0809!2sJust+Casuals!8m2!3d18.5183057!4d73.8767065!16s%2Fg%2F12ll44n8p!4m7!1m0!1m5!1m1!1s0x3bc2c045665b6e4f:0x95de9e8b4dcf0809!2m2!1d73.8767065!2d18.5183057' target='_Blank'><p>Get Direction</p></a>
                        </div>

                        <div className='col-lg-4 col-md-4 pune-add'>
                            <h4 className=''>Just Casuals, Koregaon Park</h4>
                            <p className=''>Shop No.2, Gera Legend, N Main Rd, Ragvilas Society, Koregaon Park, Pune, Maharashtra 411001</p>
                            <a className='' href='tel:+91 77700 18503'>+91 77700 18503</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//Just+Casuals,+Shop+No.2,+Gera+Legend,+N+Main+Rd,+Ragvilas+Society,+Koregaon+Park,+Pune,+Maharashtra+411001/@18.5396421,73.8870065,17z/data=!3m1!5s0x3bc2c0fdca860e17:0x153b661e990b616e!4m16!1m7!3m6!1s0x3bc2c0fdc0b4fe39:0x51cacec63cdc8e68!2sJust+Casuals!8m2!3d18.5396421!4d73.8870065!16s%2Fg%2F1pp2vddzd!4m7!1m0!1m5!1m1!1s0x3bc2c0fdc0b4fe39:0x51cacec63cdc8e68!2m2!1d73.8870065!2d18.5396421' target='_Blank'><p>Get Direction</p></a>
                        </div>
                        
                        <div className='col-lg-4 col-md-4 pune-add'>
                            <h4 className=''>Just Casuals, Aundh</h4>
                            <p className=''>Shop # 13, West End Shopping, New DP Rd, Ward No. 8, Aundh Gaon, Aundh, Pune, Maharashtra 411007</p>
                            <a className='' href='tel:+91 77700 18504'>+91 77700 18504</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//Just+Casuals,+Shop%2313,+West+End+Shopping,+New+DP+Rd,+Pune,+Maharashtra+411007/@18.56669,73.8076581,17z/data=!3m1!5s0x3bc2bf308f5d6f7f:0x432c3ab7cd8a77c3!4m8!4m7!1m0!1m5!1m1!1s0x3bc2bf321a77d759:0x160eba68f5eb915a!2m2!1d73.8076581!2d18.56669' target='_Blank'><p>Get Direction</p></a>
                        </div>
                        
                        <div className='col-lg-4 col-md-4 pune-add'>
                            <h4 className=''>Just Casuals, Viman Nagar</h4>
                            <p className=''>Shop # L-1, Neco NX, Nr. Neco Garden, Sakore Nagar, Viman Nagar, Pune, Maharashtra 411014</p>
                            <a className='' href='tel:+91 77700 18505'>+91 77700 18505</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//Just+Casuals,+Shop+%23+L-1,+Neco+NX,+Nr.+Neco+Garden,+Viman+Nagar,+Pune,+Maharashtra+411014/@18.5639009,73.913897,17z/data=!3m1!5s0x3bc2c0fd55652a41:0xa13a99370934de0b!4m8!4m7!1m0!1m5!1m1!1s0x3bc2c14429d7dd45:0x8196dd746ddfc06e!2m2!1d73.913897!2d18.5639009' target='_Blank'><p>Get Direction</p></a>
                        </div>
                        
                        <div className='col-lg-4 col-md-4 pune-add'>
                            <h4 className=''>Just Casuals, Mukund Nagar</h4>
                            <p className=''>Shop L-10, Modi Mall, 52, Shankar Rao Lohane Marg, near Ratna Building, Mukund Nagar, Pune, Maharashtra 411037</p>
                            <a className='' href='tel:+91 77700 18506'>+91 77700 18506</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//Just+Casuals,+Shop+L-10,+Modi+Mall,+52,+Shankar+Rao+Lohane+Marg,+near+Ratna+Building,+Mukund+Nagar,+Pune,+Maharashtra+411037/@18.496353,73.862483,17z/data=!3m1!5s0x3bc2c021c2f9c73f:0xefbd1b0d47bddcf8!4m8!4m7!1m0!1m5!1m1!1s0x3bc2c0178cc35155:0x88bf3d1d265ac7ec!2m2!1d73.862483!2d18.496353' target='_Blank'><p>Get Direction</p></a>
                        </div>
                        
                        <div className='col-lg-4 col-md-4 pune-add'>
                            <h4 className=''>Just Casuals, Wanowrie</h4>
                            <p className=''>Shop No 26, Nancy Towers, opp. Kedari Petrol Pump, Wanowrie, Pune, Maharashtra 411040</p>
                            <a className='' href='tel:+91 77700 18507'>+91 77700 18507</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//Shop+No+26,+Just+Casuals,+Nancy+Towers,+opp.+Kedari+Petrol+Pump,+Wanowrie,+Pune,+Maharashtra+411040/@18.4874375,73.8989683,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2ea78759d9b0f:0xf59f1d6b7e66261c!2m2!1d73.8989683!2d18.4874375' target='_Blank'><p>Get Direction</p></a>
                        </div>

                        <div className='col-lg-4 col-md-4 pune-add'>
                            <h4 className=''>Just Casuals, Kothrud</h4>
                            <p className=''>Kakade Complex, Karve Rd, Dahanukar A, Dahanukar Colony, Kothrud, Pune, Maharashtra 411038</p>
                            <a className='' href='tel:+91 77700 18508'>+91 77700 18508</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//Just+Casuals,+Kakade+Complex,+Karve+Rd,+Dahanukar+A,+Dahanukar+Colony,+Kothrud,+Pune,+Maharashtra+411038/@18.4992304,73.8134509,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2bfc13b6d105f:0xebdb9bd9642f620a!2m2!1d73.8134804!2d18.4992306' target='_Blank'><p>Get Direction</p></a>
                        </div>

                        <div className='col-lg-4 col-md-4 pune-add'>
                            <h4 className=''>Just Casuals, Sinhagad Road</h4>
                            <p className=''>Shop No.5, Fun Time Multiplex, Petrol Pump, Near, Sinhgad Rd, Manik Baug, Sinhagad, Pune, Maharashtra 411041</p>
                            <a className='' href='tel:+91 77700 18509'>+91 77700 18509</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//Just+Casuals,+Petrol+Pump,+Near,+Shop+No.5,+Fun+Time+Multiplex,+Sinhgad+Rd,+Manik+Baug,+Sinhagad,+Pune,+Maharashtra+411041/@18.4682827,73.8194739,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc29542b77f25e1:0xd8508ccf6419ec02!2m2!1d73.8194739!2d18.4682827' target='_Blank'><p>Get Direction</p></a>
                        </div>

                        <div className='col-lg-4 col-md-4 pune-add'>
                            <h4 className=''>Just Casuals, Pimple Saudagar</h4>
                            <p className=''>Shop No.1 & 2, Shyama Prestige, Wing-B, Rahatani Rd, opp. VIJAY SALES - RAHATANI, Pimple Saudagar, Pimpri-Chinchwad, Maharashtra 411027</p>
                            <a className='' href='tel:+91 77700 18510'>+91 77700 18510</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//Just+Casuals,+Shop+No.1+%26+2,+Shyama+Prestige,+Wing-B,+Rahatani+Rd,+opp.+VIJAY+SALES+-+RAHATANI,+Pimple+Saudagar,+Pimpri-Chinchwad,+Maharashtra+411027/@18.60126,73.794758,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2b8e27851f17d:0xafdd132fb807aa17!2m2!1d73.794758!2d18.60126' target='_Blank'><p>Get Direction</p></a>
                        </div>

                        <div className='col-lg-4 col-md-4 pune-add'>
                            <h4 className=''>Just Casuals, Chinchwad</h4>
                            <p className=''>202, "Gawade Estate", Mumbai - Pune Road Near Chinchwad, Station, Anna Sahib Nagar, Chinchwad, Pune, Maharashtra 411019</p>
                            <a className='' href='tel:+91 77700 18512'>+91 77700 18512</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//Just+Casuals,+202,+Gawade+Estate%22,+Mumbai+-+Pune+Road+Near+Chinchwad,+Station,+Pune,+Maharashtra+411019/@18.6401463,73.7942779,17z/data=!3m1!5s0x3bc2b9cab1d76e89:0x6d1aec918a41709c!4m8!4m7!1m0!1m5!1m1!1s0x3bc2b9caaf1eb8b9:0x299e4f8375965c62!2m2!1d73.7942779!2d18.6401463' target='_Blank'><p>Get Direction</p></a>
                        </div>

                        <div className='col-lg-4 col-md-4 pune-add'>
                            <h4 className=''>Just Casuals, Nigdi</h4>
                            <p className=''>Shop 1 & 2, Agarwal Business Centre, Sector No. 25, PCNTDA, Near - Bhel, Chowk,, Sector 25, Pradhikaran, Nigdi, Pune, Maharashtra 411044</p>
                            <a className='' href='tel:+91 77740 13133'>+91 77740 13133</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//Just+Casuals,+Shop+1+%26+2,+Agarwal+Business+Centre,+Sector+No.+25,+PCNTDA,+Near+-+Bhel,+Chowk,,+Pune,+Maharashtra+411044/@18.6570582,73.7710969,17z/data=!4m16!1m7!3m6!1s0x3bc2b9e083345d3d:0x2a77f96a29b7a638!2sJust+Casuals!8m2!3d18.6570582!4d73.7710969!16s%2Fg%2F11hbgrvl4w!4m7!1m0!1m5!1m1!1s0x3bc2b9e083345d3d:0x2a77f96a29b7a638!2m2!1d73.7710969!2d18.6570582' target='_Blank'><p>Get Direction</p></a>
                        </div>

                        <div className='st-map'>
                            <iframe src="https://www.google.com/maps/d/embed?mid=1BzLJCk9uthuVul0dFsaMRL3lvQTqEmo&ehbc=2E312F" width="100%" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
                <div id="tab-4" className="tab-content">
                    <div className='row'>
                        <div className='col-lg-4 col-md-4'>
                            <h4 className=''>Just Casuals, Goa</h4>
                            <p className=''>Navelkar Trade Center, 15, Ground <br className='d-none d-lg-block' /> Floor, MG Road, Altinho, Panaji, <br className='d-none d-lg-block' />  Goa 403001</p>
                            <a className='' href='tel:'>04442033882</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//Just+Casuals,+Navelkar+Trade+Center,+15,+Ground+Floor,+MG+Road,+Altinho,+Panaji,+Goa+403001/@15.4995062,73.8243242,17z/data=!3m1!5s0x3bbfc0892fd5a5c9:0x8b138502e1b7a1e6!4m8!4m7!1m0!1m5!1m1!1s0x3bbfc0892e2e1e8b:0x9e027f321e8eb2fe!2m2!1d73.8265129!2d15.499501?hl=en' target='_Blank'><p>Get Direction</p></a>
                        </div>

                        <div className='st-map'>
                            <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1FzVHMQugmudxDZn4IRQFGi12wPzVZv8&ehbc=2E312F" width="100%" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
                <div id="tab-5" className="tab-content">
                    <div className='row'>
                        <div className='col-lg-4 col-md-4'>
                            <h4 className=''>Just Casuals, Bally </h4>
                            <p className=''>G9H8+62M, Circular Rd, Ballygunge, <br className='d-none d-lg-block' /> Kolkata, West Bengal 700019 </p>
                            <a className='' href='tel:09831733669'>09831733669</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//G9H8%2B62M+Just+Casuals,+Circular+Rd,+Ballygunge,+Kolkata,+West+Bengal+700019/@22.5280964,88.3650404,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3a0276d7808c9f13:0xa86d32afb6d0f9ed!2m2!1d88.3650404!2d22.5280964?hl=en' target='_Blank'><p>Get Direction</p></a>
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            <h4 className=''>Just Casuals, Avani</h4>
                            <p className=''>32, Jagat Banerjee Ghat Rd, Choura <br className='d-none d-lg-block' /> Bustee, Shibpur, Howrah, West <br className='d-none d-lg-block' /> Bengal 711102 </p>
                            <a className='' href='tel:09831733669'>09831733669</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//Just+Casuals+Avani,+32,+Jagat+Banerjee+Ghat+Rd,+Choura+Bustee,+Shibpur,+Howrah,+West+Bengal+711102/@22.563101,88.323639,17z/data=!3m1!5s0x3a0279d556be4543:0x3bc30672ec58375b!4m8!4m7!1m0!1m5!1m1!1s0x3a0279d556ab863d:0x51df6502e0f8f3c4!2m2!1d88.323639!2d22.563101?hl=en' target='_Blank'><p>Get Direction</p></a>
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            <h4 className=''>Just Casuals, Garia </h4>
                            <p className=''>1B, Garia Station Rd, Barhans, Garia, <br className='d-none d-lg-block' /> Kolkata, West Bengal 700084</p>
                            <a className='' href='tel:09831733669'>09831733669</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//Just+Casuals+Garia,+1B,+Garia+Station+Rd,+Barhans,+Garia,+Kolkata,+West+Bengal+700084/@22.4634347,88.3805299,18.5z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3a0271a18f21bd15:0x762972dc95762686!2m2!1d88.3805461!2d22.4634956' target='_Blank'><p>Get Direction</p></a>
                        </div>

                        <div className='st-map'>
                            <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1O_E5k_U5GTnUunPHVSlvHKqNjNkJiFw&ehbc=2E312F" width="100%" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
                <div id="tab-6" className="tab-content">
                    <div className='row'>
                        <div className='col-lg-4 col-md-4'>
                            <h4 className=''>Just Casuals, Karachi Khana</h4>
                            <p className=''>24/25, Didwania Bhawan,<br className='d-none d-lg-block' /> Karachi Khana, Kanpur, Uttar Pradesh <br className='d-none d-lg-block' />208001</p>
                            <a className='' href='tell:'>04442033882</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//Just+Casuals,+24%2F25,+Didwania+Bhawan,+Karachi+Khana,+Kanpur,+Uttar+Pradesh+208001/@26.4639211,80.3574813,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x399c4750d5fc7c19:0x1490b26f1eea4474!2m2!1d80.3574813!2d26.4639211' target='_Blank'><p>Get Direction</p></a>
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            <h4 className=''>Just Casuals, Swaroop Nagar</h4>
                            <p className=''>113, Mata Swaruprani Rd, Khalasi Line, <br className='d-none d-lg-block' /> Swaroop Nagar, Kanpur, Uttar Pradesh <br className='d-none d-lg-block' /> 208002</p>
                            <a className='' href='tell:'>04442033882</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//26.4818889,80.31225/@26.4818937,80.3100613,17z?hl=en-US' target='_Blank'><p>Get Direction</p></a>
                        </div>

                        <div className='st-map'>
                            <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1weYFNO_UrK5HdezcLaIjcw8f8Gq_1bY&ehbc=2E312F" width="100%" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
                <div id="tab-7" className="tab-content">
                    <div className='row'>
                        <div className='col-lg-4 col-md-4'>
                            <h4 className=''>Just Casuals, Siliguri</h4>
                            <p className=''>PCGH+6HH, Janta, Ward 10, Siliguri, <br className='d-none d-lg-block' /> West Bengal 734001 </p>
                            <a className='' href='tell:04423638443'>04423638443</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//PCGH%2B6HH+Just+Casuals,+Janta,+Ward+10,+Siliguri,+West+Bengal+734001/@26.7255576,88.4289925,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x39e44111b080dfbf:0x1b71b6a3b4961339!2m2!1d88.4289925!2d26.7255576?hl=en-IN' target='_Blank'><p>Get Direction</p></a>
                        </div>

                        <div className='st-map'>
                            <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1C4m3AAbOVfQcNuzrV621L0wjsc_19c4&ehbc=2E312F" width="100%" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
                <div id="tab-8" className="tab-content">
                    <div className='row'>
                        <div className='col-lg-4 col-md-4'>
                            <h4 className=''>Just Casuals, Surat</h4>
                            <p className=''>Shop no G-7,8, Speranza Business Hub, <br className='d-none d-lg-block' /> New Pal Rd, beside Raj Victoria, Adajan Gam, <br className='d-none d-lg-block' />Adajan, Surat, Gujarat 395009 </p>
                            <a className='' href='tel:09687770708'>09687770708</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//Shop+no+G-7,8,+JUST+CASUALS,+Speranza+Business+Hub,+New+Pal+Rd,+beside+Raj+Victoria,+Adajan+Gam,+Adajan,+Surat,+Gujarat+395009/@21.1930614,72.7811806,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3be04d3f89c65137:0xdfb41e39607b2070!2m2!1d72.7811806!2d21.1930614' target='_Blank'><p>Get Direction</p></a>
                        </div>

                        <div className='st-map'>
                            <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1Pl1zH0ds4H8OGWigIfXMB4_dTXGJvrM&ehbc=2E312F" width="100%" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
                <div id="tab-9" className="tab-content">
                    <div className='row'>
                        <div className='col-lg-4 col-md-4'>
                            <h4 className=''>Just Casual - Pahiraan</h4>
                            <p className=''>7HRR+HJ7, Ranipool, Sikkim 737135</p>
                            <a className='' href='tell:08388945388'>08388945388</a>
                            <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
                            <a className='st-btn' href='https://www.google.com/maps/dir//7HRR%2BHJ7+Just+casual+-+Pahiraan,+Ranipool,+Sikkim+737135/@27.2897445,88.5890407,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x39e6a5ebc886de7d:0xa7913c52e732a8a!2m2!1d88.5915537!2d27.2914168?hl=en' target='_Blank'><p>Get Direction</p></a>
                        </div>

                        <div className='st-map'>
                            <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1FezYpeFChNmCUD45vYhjlLOrrm0xDxY&ehbc=2E312F" width="100%" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            {/* ST Tabs End */}
             
        </div>

      <Footer />
      </>
    )
  }
}
