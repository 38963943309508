// import React, { Component } from 'react';

// class InstaFeed extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       posts: [],
//       error: null
//     };
//   }

//   componentDidMount() {
//     const accessToken = 'IGQVJWUE9IMWxaaDctYTU0aE1zcHVGMkNKOFFUSUhpSTBnZAHQxeVBPT2ZApcmhFd1VfYlNodThjWk1qRmJ5SnZAYcVMySnNhM1lidXdPUHAyaFRkSnJVb21mM0ZAoZAEt5V1RFejhJd0FWblVLTHBtNV9ZAUAZDZD';
//     const apiUrl = `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp&access_token=${accessToken}&limit=4`;
    
//     fetch(apiUrl)
//       .then(response => response.json())
//       .then(data => {
//         this.setState({ posts: data.data });
//       })
//       .catch(error => {
//         this.setState({ error });
//       });
//   }

//   render() {
//     const { posts, error } = this.state;

//     if (error) {
//       return <div>Error: {error.message}</div>;
//     } else if (posts.length === 0) {
//       return <div>Loading...</div>;
//     } else {
//       const imagePosts = posts.filter(post => post.media_type === 'IMAGE');
//       const videoPosts = posts.filter(post => post.media_type === 'VIDEO');
//       const emptyDivs = new Array(4 - imagePosts.length - videoPosts.length).fill(<div className='col-lg-3 col-6'></div>);
//       return (
//         <div className='row'>
//           {imagePosts.map(post => (
//             <div className='col-lg-3 col-6' key={post.id}>
//               <img src={post.media_url} alt={post.caption} />
//             </div>
//           ))}
//           {videoPosts.map(post => (
//             <div className='col-lg-3 col-6' key={post.id}>
//               <div style={{position: 'relative'}}>
//                 <img src={post.thumbnail_url} alt={post.caption} />
//                 <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
//                   <svg width="50" height="50" viewBox="0 0 24 24" fill="#fff">
//                     <path d="M8 5v14l11-7z"/>
//                   </svg>
//                 </div>
//               </div>
//             </div>
//           ))}
//           {emptyDivs}
//         </div>
//       );
//     }
//   }
// }

// export default InstaFeed;

import React, { Component } from 'react';
import Dummy from '../assets/img/dummyinsta.png'

class InstaFeed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      error: null
    };
  }

  componentDidMount() {
    const accessToken = 'IGQVJYcURsV19PdDlCUmluOHptSWtaV3A2bWs2V211VUsyaXBZANldhV0Qtb2RlajlqQ0FVbGJnbWpxQ2lFbGlRWWpIamE0TkJRLWFnaV9Rblh4b1c0MzlWZAWRyaDZAJSEZAILS1CVkxad0lfZAkFoYmxFWAZDZD';
    const apiUrl = `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp&access_token=${accessToken}&limit=4`;
    
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        console.log(data); // Log the response data to inspect its structure
        if (data.data) {
          this.setState({ posts: data.data });
        } else {
          throw new Error('Invalid response data');
        }
      })
      .catch(error => {
        this.setState({ error });
      });
  }

  render() {
    const { posts, error } = this.state;

    if (error) {
      // return <div>Error: {error.message}</div>;
      return <img src={Dummy} className="img-fluid" alt="Dummy" />
    } else if (posts.length === 0) {
      return <div>Loading...</div>;
    } else {
      const imagePosts = posts.filter(post => post.media_type === 'IMAGE');
      const videoPosts = posts.filter(post => post.media_type === 'VIDEO');
      const emptyDivs = new Array(4 - imagePosts.length - videoPosts.length).fill(<div className='col-lg-3 col-6'></div>);
      return (
        <div className='row'>
          {imagePosts.map(post => (
            <div className='col-lg-3 col-6' key={post.id}>
              <img src={post.media_url} alt={post.caption} />
            </div>
          ))}
          {videoPosts.map(post => (
            <div className='col-lg-3 col-6' key={post.id}>
              <div style={{position: 'relative'}}>
                <img src={post.thumbnail_url} alt={post.caption} />
                <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                  <svg width="50" height="50" viewBox="0 0 24 24" fill="#fff">
                    <path d="M8 5v14l11-7z"/>
                  </svg>
                </div>
              </div>
            </div>
          ))}
          {emptyDivs}
        </div>
      );
    }
  }
}

export default InstaFeed;
