import React, { Component } from 'react'
import Lenis from '@studio-freight/lenis'
import Logo from './../assets/img/logo.png'
import Contact from './contact'

export default class Header extends Component {
  render() {

    const lenis = new Lenis({
      duration: 1.5,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), 
      direction: 'vertical',
      gestureDirection: 'vertical',
      smooth: true,
      mouseMultiplier: 1,
      smoothTouch: false,
      touchMultiplier: 2,
      infinite: false,
    })
    
    
    function raf(time) {
      lenis.raf(time)
      requestAnimationFrame(raf)
    }
    
    requestAnimationFrame(raf)
    
    return (
      <>
          <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
              <div className="modal-content">
                <div className="container">
                  <button className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body container">
                  <Contact />
                </div>
              </div>
            </div>
          </div>
        <header id='header' className='container'>
            {/* <nav className="navbar navbar-expand-lg navbar-light d-none">
                <div className="container-fluid nav-resp">
                    <a className="navbar-brand" href="/#">
                        <img className='img-fluid' src={Logo} alt="Just-casuals" />
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className='d-none d-lg-block'>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                        <a className="nav-link" href="/about-us">About</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" href="/business-opportunities">Business Opportunities</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" href="/">Private Labels</a>
                        </li>
                        <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="/" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Find a Store
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li><a className="dropdown-item" href="/">Action</a></li>
                            <li><a className="dropdown-item" href="/">Another action</a></li>
                            <li><a className="dropdown-item" href="/">Something else here</a></li>
                        </ul>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link" href="/">Blog</a>
                        </li>
                        <li className="nav-item">
                        <a className="nav-link modal-buu" data-bs-toggle="modal" data-bs-target="#exampleModal">Contact</a>
                        </li>
                    </ul>
                    </div>
                    </div>
                </div>
            </nav> */}

            <div className='container nav-head'>
              <a className="navbar-brand" href="/">
                <img className='img-fluid' src={Logo} alt="Just-casuals" />
              </a>

              <input className="menu-icon d-block d-lg-none" type="checkbox" id="menu-icon" name="menu-icon"/>
              <label htmlFor="menu-icon"></label>
              <nav className="nav"> 		
                <ul className="nav-menu">
                  <li><a href="/about-us">About</a></li>
                  <li><a href="/business-opportunities">Business Opportunities</a></li>
                  <li><a href="/excess-inventory">Excess Inventory</a></li>
                  <li><a href="/find-a-store">Find a Store</a></li>
                  <li><a href="/" className='modal-buu' data-bs-toggle="modal" data-bs-target="#exampleModal">Contact</a></li>
                </ul>
              </nav>
            </div>
        </header>
        
      </>
    )
  }
}
