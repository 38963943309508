import React, { Component, useEffect, useState, useRef } from "react";
import axios, * as others from 'axios';
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import Api from "../../package.json";
// import ContactP from '../assets/img/contact-hero.png'
const Contact = (props) => {

  const {
    register,
    handleSubmit, setValue,
    formState: { errors },
  } = useForm();
  const buttonRef = useRef();
  const [SubmitButton, setSubmitButton] = useState("Submit");
  const onSubmit = (data) => {
     buttonRef.current.disabled = true;
    setSubmitButton("submitting..."); 
    get_contact(data);
  };
  const get_contact = async (data) => {


    const bodyFormData = new FormData();
    bodyFormData.append("name", data.name);
    bodyFormData.append("phone", data.phone);
    bodyFormData.append("mail", data.mail);
    bodyFormData.append("enquiry", data.enquiry);
    bodyFormData.append("action", 'contact');
    const response = await axios({
      method: "post",
      url: Api.userdata.url + "/custom_api.php",
      data: bodyFormData,
    }
    );
    const json = await response.data;
    if (json["status"] == "success") {
      swal("Thanks so much for reaching out to us!", json["status"], "success").then((value) => {
        window.location.reload(true);
      });
    } else {
      swal("Error !", json["status"], "error");
    }



    buttonRef.current.disabled = false;
    setSubmitButton("Submit");
  }
    return (
      <div className='contact-popup'>
        <div className='row'>
          <div className='col-lg-5'>
            <div className='img-side'>
              <h2>Send Us A <br/>Message</h2>
              {/* <img className='img-fluid' src={ContactP} /> */}

              <a className='' href='mailto:justcasuals@gmail.com'>justcasuals@gmail.com</a>
            </div>
          </div>
          <div className='col-lg-3'></div>
          <div className='col-lg-4'>
            <div className='form-side'>
              {/* <h2>Contact us</h2> */}
              <form   onSubmit={handleSubmit(onSubmit)}
                autoComplete="on"
                name="point-to-point-new"
                id="point-to-point-new"
                method="POST" >
                <div className='bo-forms'>
                    <fieldset className='bo-forms-spc'>
                        <label>Name <sup>*</sup></label>
                        <input className='fs-inpt' type='text' name='name' placeholder='Name'  {...register("name", {
                        required: {
                          value: true,
                          message: "Please enter your name",
                        },
                      })} /> {errors.name && (
                        <span className="errors">{errors.name.message}</span>
                      )}
                    </fieldset>
                    <fieldset className='bo-forms-spc'>
                        <label>Phone <sup>*</sup></label>
                        <input className='fs-inpt' type='number' name='phone' placeholder='Phone'  {...register("phone", {
                        required: {
                          value: true,
                          message: "Please enter your phone number",
                        },
                        minLength: {
                          value: 6,
                          message: "Invalid phone number",
                        },
                        maxLength: {
                          value: 15,
                          message: "Invalid phone number",
                        },
                        pattern: {
                          value: /^-?[0-9]\d*\.?\d*$/i,
                          message: "Invalid",
                        },
                      })} /> {errors.phone && (
                        <span className="errors">{errors.phone.message}</span>
                      )}
                    </fieldset>
                    <fieldset className='bo-forms-spc'>
                        <label>Email ID <sup>*</sup></label>
                        <input className='fs-inpt' type='email' name='mail' placeholder='Email ID' {...register("mail", {
                        required: {
                          value: true,
                          message: "Please enter your email id",
                        },   pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",}
                        
                      })} /> {errors.mail && (
                        <span className="errors">{errors.mail.message}</span>
                      )}
                    </fieldset>
                    <fieldset className='bo-forms-spc'>
                        <label>What’s your Enquiry about</label>
                        <textarea className='fs-inpt' name='enquiry'  {...register("enquiry", {
                        required: {
                          value: true,
                          message: "Please enter your enquiry",
                        },
                      })} /> {errors.enquiry && (
                        <span className="errors">{errors.enquiry.message}</span>
                      )}
                    </fieldset>
                    <div className='bo-forms-spc'>
                    <button
                      className='submit-btn'
                      id="submitDetails"
                      name="my-buttons"
                      ref={buttonRef}
                      type="submit"
                    >
                      {SubmitButton}
                    </button>
                    </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  export default Contact
