import React, { Component, useEffect, useState, useRef } from "react";
import axios from 'axios';
import { useForm } from "react-hook-form";
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Lottie from 'lottie-react'
import Footer from '../components/footer'
import Header from '../components/header'
import BoHero from './../assets/img/bohero-img.png'
import BoHerostc from './../assets/img/bohero-bg1-1.png'
import BoS2Top from './../assets/img/bos2top.svg'
import BoS2Image1 from './../assets/img/bos2image1.png'
import BoS3Top from './../assets/img/bos3top.svg'
import BoS3Mid from './../assets/img/bos3mid.svg'
import BOjson1 from './../assets/img/jc-bo-json1.json'
import BOjson2 from './../assets/img/jc-bo-json2.json'
import BOjson3 from './../assets/img/jc-bo-json3.json'
import swal from "sweetalert";
import Api from "../../package.json";
import { FileUploader } from "react-drag-drop-files";
const fileTypes = ["JPG", "PNG", "GIF"];
const BusinessOppurtunities = (props) => {

  const {
    register,
    handleSubmit, setValue,
    formState: { errors }, getValues,
  } = useForm();
  const buttonRef = useRef();
  const [SubmitButton, setSubmitButton] = useState("Submit");
  const fill_val_addition_doc = () => {
    var picture = getValues(`picture`);
    var picture_text = " Selected Files : ";

    var valid_file = true;
    if(picture == undefined )
    {
      valid_file = false;
      picture_text = "Nothing selected";
    }
    else if ( picture.length <= 5 && picture.length > 0) {

      for (var k = 0; k < picture.length; k++) {
        if (picture.length - 1 == k) {
          picture_text += picture[k]['name'];
        }
        else {
          picture_text += picture[k]['name'] + ",";
        }

      }

    }
    else if (picture.length >= 5) {
      valid_file = false;
      picture_text = "Maximum 5 files only allowed";
    }
    else {
      valid_file = false;
      picture_text = "Nothing selected";
    }
    setValue(`picture_text`, picture_text);
    return valid_file;
  };
  const businessoppGsap = () => {

    gsap.registerPlugin(ScrollTrigger)

    ScrollTrigger.saveStyles(".mobile, .desktop");

    ScrollTrigger.matchMedia({

      // desktop
      "(min-width: 1024px)": function () {

        // Image Entrance 1
        gsap.set(".ifscroll1", { scale: 0, opacity: 0.2 });
        gsap.to('.ifscroll1', {
          opacity: 1,
          scale: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".aboutscroll",
            start: "top center",
            end: "bottom top",
          }
        })

        // Image Entrance 2
        gsap.set(".ifscroll2", { scale: 0, opacity: 0.2 });
        gsap.to('.ifscroll2', {
          opacity: 1,
          scale: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".aboutscroll",
            start: "top center",
            end: "bottom top",

          }
        })

        // Image Entrance 3
        gsap.set(".ifscroll3", { scale: 0, opacity: 0.2 });
        gsap.to('.ifscroll3', {
          opacity: 1,
          scale: 1,
          delay: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".about-rt",
            start: "top center",
            end: "bottom top",

          }
        })

        // Image Entrance 4
        gsap.set(".ifscroll4", { scale: 0, opacity: 0.2 });
        gsap.to('.ifscroll4', {
          opacity: 1,
          scale: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".about-rt",
            start: "top center",
            end: "bottom top",

          }
        })

        // Image Entrance 5
        gsap.set(".ifscroll5", { scale: 0, opacity: 0 });
        gsap.to('.ifscroll5', {
          opacity: 1,
          scale: 1,
          delay: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".about-rt",
            start: "top center",
            end: "bottom top",

          }
        })

        // Image Entrance json1
        gsap.set(".json1", { scale: 0, opacity: 0 });
        gsap.to('.json1', {
          opacity: 1,
          scale: 1,
          delay: 0.3,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".element",
            start: "top center",
            end: "bottom top",

          }
        })

        // Image Entrance json2
        gsap.set(".json2", { scale: 0, opacity: 0 });
        gsap.to('.json2', {
          opacity: 1,
          scale: 1,
          delay: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".element",
            start: "top center",
            end: "bottom top",

          }
        })

        // Image Entrance json3
        gsap.set(".json3", { scale: 0, opacity: 0 });
        gsap.to('.json3', {
          opacity: 1,
          scale: 1,
          delay: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".bo-s2-image-wrap",
            start: "top center",
            end: "bottom top",

          }
        })

        // Image Entrance Img
        gsap.set(".bo-imgstc img", { scale: 0, opacity: 0 });
        gsap.to('.bo-imgstc img', {
          opacity: 1,
          scale: 1,
          delay: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".bo-hero",
            start: "top center",
            end: "bottom top",

          }
        })



        // Image Float 1
        gsap.to('.ifscroll1', {
          yPercent: -20,
          xPercent: -10,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".aboutscroll",
            start: "top center",
            end: "bottom top",
            scrub: true
          }
        })

        // Image Float 2
        gsap.to('.ifscroll2', {
          yPercent: -20,
          xPercent: -20,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".aboutscroll",
            start: "top center",
            end: "bottom top",
            scrub: true
          }
        })

        // Image Float 3
        gsap.to('.ifscroll3', {
          yPercent: -20,
          xPercent: -10,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".about-rt",
            start: "top center",
            end: "bottom top",
            scrub: true
          }
        })

        // Image Float 4
        gsap.to('.ifscroll4', {
          yPercent: 10,
          xPercent: 30,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".about-rt",
            start: "top center",
            end: "bottom top",
            scrub: true
          }
        })
      },

      // mobile
      "(max-width: 799px)": function () {

        // Image Entrance json1
        gsap.set(".json1", { scale: 0, opacity: 0 });
        gsap.to('.json1', {
          opacity: 1,
          scale: 1,
          delay: 0.5,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".element",
            start: "top center",
            end: "bottom top",

          }
        })

        // Image Entrance json2
        gsap.set(".json2", { scale: 0, opacity: 0 });
        gsap.to('.json2', {
          opacity: 1,
          scale: 1,
          delay: 1.1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".element",
            start: "top center",
            end: "bottom top",

          }
        })

        // Image Entrance json3
        gsap.set(".json3", { scale: 0, opacity: 0 });
        gsap.to('.json3', {
          opacity: 1,
          scale: 1,
          delay: 0.6,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".bo-s2-image-wrap",
            start: "top center",
            end: "bottom top",

          }
        })


        // Image Entrance 1
        gsap.set(".ifscroll1", { scale: 0, opacity: 0.2 });
        gsap.to('.ifscroll1', {
          opacity: 1,
          scale: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".aboutscroll",
            start: "top center",
            end: "bottom top",
          }
        })
        // Image Float 1
        gsap.to('.ifscroll1', {
          yPercent: -30,
          xPercent: -10,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".aboutscroll",
            start: "top center",
            end: "bottom top",
            scrub: true
          }
        })

        // Image Entrance 2
        gsap.set(".ifscroll2", { scale: 0, opacity: 0.2 });
        gsap.to('.ifscroll2', {
          opacity: 1,
          scale: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".aboutscroll",
            start: "top center",
            end: "bottom top",

          }
        })
        // Image Float 2
        gsap.to('.ifscroll2', {
          yPercent: -20,
          xPercent: -10,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".aboutscroll",
            start: "top center",
            end: "bottom top",
            scrub: true
          }
        })

        // Image Entrance 5
        gsap.set(".ifscroll5", { scale: 0, opacity: 0.2 });
        gsap.to('.ifscroll5', {
          opacity: 1,
          scale: 1,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".aboutscroll",
            start: "top top",
            end: "bottom top",

          }
        })
        // Image Entrance 6
        gsap.set(".ifscroll6", { scale: 0, opacity: 0.2 });
        gsap.to('.ifscroll6', {
          opacity: 1,
          scale: 1,
          delay: 0.8,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".bo-home",
            start: "top center",
            end: "bottom top",

          }
        })
        // Image Entrance 7
        gsap.set(".ifscroll7", { scale: 0, opacity: 0.2 });
        gsap.to('.ifscroll7', {
          opacity: 1,
          scale: 1,
          delay: 0.5,
          ease: "easeOut",
          scrollTrigger: {
            trigger: ".cl-slider",
            start: "top center",
            end: "bottom top",

          }
        })

      },

      // all 
      "all": function () {
      }

    });

    gsap.set(".workanimation", { visibility: "hidden" });
    ScrollTrigger.batch(".workanimation", {
      onEnter: batch => {
        gsap.from(batch, {
          duration: 1,
          delay: 0.5,
          yPercent: 50,
          scale: 1,
          autoAlpha: 0,
          ease: "Power3.easeOut",
          stagger: 0.4,
          visibility: "visible",
        })
      },
      toggleActions: "play none none none",
      once: true,
      start: 'top 80%',
      end: 'top 40%',
      markers: false,
    });
    gsap.set(".workanimation1", { visibility: "hidden" });
    ScrollTrigger.batch(".workanimation1", {
      onEnter: batch => {
        gsap.from(batch, {
          duration: 1,
          delay: 0.5,
          yPercent: 50,
          scale: 1,
          autoAlpha: 0,
          ease: "Power3.easeOut",
          stagger: 0.4,
          visibility: "visible",
        })
      },
      toggleActions: "play none none none",
      once: true,
      start: 'top 70%',
      end: 'top 40%',
      markers: false,
    });

    //ProcessIMG 
    gsap.set(".cp-image-reveal", { opacity: 0 });
    gsap.to(".cp-image-reveal", {
      opacity: 1,
      ease: "Power3.easeOut",
      delay: 1.5,
      duration: 1
    });

    //Our APPROACH
    gsap.set(".process-approach .line h1", { y: 180, rotateZ: 6 });
    gsap.to(".process-approach .line h1", {
      y: 0,
      rotateZ: 0,
      ease: "Power3.easeOut",
      delay: 0,
      stagger: {
        amount: 0.2
      },
      scrollTrigger: {
        trigger: ".process-approach .line h1",
        start: "top 90%",
      }
    });

    //Our APPROACH
    gsap.set(".bo-s2-content .line span", { y: 180, rotateZ: 6 });
    gsap.to(".bo-s2-content .line span", {
      y: 0,
      rotateZ: 0,
      ease: "Power3.easeOut",
      delay: 0,
      stagger: {
        amount: 0.2
      },
      scrollTrigger: {
        trigger: ".bo-s2-content .line span",
        start: "top 90%",
      }
    });

    gsap.set(".owpimg-desktop2", { opacity: 0 });
    gsap.to(".owpimg-desktop2", {
      opacity: 1,
      duration: 1,
      delay: 0.8,
      ease: "Power3.easeOut",
      scrollTrigger: {
        trigger: ".owpimg-desktop2",
        start: 'top 70%'
      }
    });
  }
  useEffect(() => {
    businessoppGsap();



  }, []);
  const onSubmit = (data) => {
    if (fill_val_addition_doc() == true) {
      buttonRef.current.disabled = true;
      setSubmitButton("submitting...");
      save_contacts(data);
    }
  };

  const save_contacts = async (data) => {
    const bodyFormData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key == 'picture') {
        for (var i = 0; i < data[key].length; i++) {

          bodyFormData.append('picture[' + i + ']', data[key][i]);

        }
      }
    });

    bodyFormData.append("name", data.name);
    bodyFormData.append("email", data.email);
    bodyFormData.append("phone", data.phone);
    bodyFormData.append("location", data.location);
    bodyFormData.append("store", data.store);
    bodyFormData.append("city", data.city);
    bodyFormData.append("store1", data.store1);
    bodyFormData.append("self_owned", data.self_owned);
    bodyFormData.append("leased", data.leased);
    bodyFormData.append("picture", data.picture);
    bodyFormData.append("action", 'business_opportunities');
    const response = await axios({
      method: "post",
      url: Api.userdata.url + "/custom_api.php",
      data: bodyFormData,
    }
    );
    const json = await response.data;
    if (json["status"] == "success") {
      swal("Thanks so much for reaching out to us!", json["status"], "success").then((value) => {
        window.location.reload(true);
      });
    } else {
      swal("Error !", json["status"], "error");
    }



    buttonRef.current.disabled = false;
    setSubmitButton("Submit");

  }
  {
    return (
      <>
        <Header />

        <div className='business-oppurtunities'>

          {/* Hero Section */}
          <div className='bo-hero'>
            <div className='container '>
              <div className='bo-hero-content-1 process-approach'>
                <div className='row'>
                  <div className='col-lg-7'>
                    <div className='line'>
                      <h1>
                        <span>Business</span>
                      </h1>
                    </div>
                    <div className='line'>
                      <h1 className='h1before line'>
                        <span>Opportunities</span>
                      </h1>
                    </div>
                  </div>
                  <div className='col-lg-1'></div>
                  <div className='col-lg-4 elements'>
                    {/* <img alt='Just-casuals' /> */}
                    {/* <img alt='Just-casuals' /> */}
                    <div className='bo-lott1 json1'>
                      <Lottie
                        loop
                        animationData={BOjson1}
                        play
                      />
                    </div>
                    <h4 className='workanimation'>“You’ve got this. <br />Our clothing has <br />got you.”</h4>
                    <div className='bo-lott2 json2'>
                      <Lottie
                        loop
                        animationData={BOjson2}
                        play
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='bo-hero-content-2'>
                <div className='row resp-flx-dr'>
                  <div className='col-lg-1'></div>
                  <div className='col-lg-5'>
                    <h3 className='workanimation1'>Work with the fastest-growing surplus apparel retailer in India to run your own business while gaining access to our family-like support network.</h3>
                  </div>
                  <div className='col-lg-1'></div>
                  <div className='col-lg-5'>
                    <img src={BoHero} className="img-fluid cp-image-reveal" alt='Just-casuals' />
                  </div>
                </div>
              </div>
            </div>
            <div className='bo-hero-imgstc bo-imgstc'>
              <img src={BoHerostc} className="img-fluid cp-image-reveal" alt='Just-casuals' />
            </div>
          </div>

          {/* Section2 */}
          <div className='bo-s2'>
            <img src={BoS2Top} className="bo-s2-top" alt='Just-casuals' />

            <div className="bo-marquee marquee-animate">
              <div className="track">
                <div className="content extra-button-x1">&nbsp;Requirements <span></span> Requirements <span></span> Requirements <span></span> Requirements <span></span> Requirements <span></span> Requirements <span></span> Requirements <span></span> Requirements <span></span> Requirements <span></span></div>
              </div>
            </div>

            <div className='container bo-s2-content'>
              <div className='row'>
                <div className='col-lg-5'>
                  <div className='bo-s2-text'>
                    <div className='bo-s2-inner-text'>
                      <h3 className='line'>
                        <span>600 - 1000 sq. ft</span>
                      </h3>
                      <p className='workanimation'>Retail space area with road facing and on the ground floor with a good visibility.</p>
                    </div>
                    <div className='bo-s2-inner-text'>
                      <h3 className='line'>
                        <span>Your Investment</span>
                      </h3>
                      <p className='workanimation'>Investment varies by store location and size</p>
                    </div>
                    <div className='bo-s2-inner-text'>
                      <h3 className='line'>
                        <span>Ample Parking</span>
                      </h3>
                      <p className='workanimation'>Parking should be easily  accessible and ample, with extra spots for valet and drop offs.</p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-1'></div>
                <div className='col-lg-5'>
                  <div className='bo-s2-image-wrap'>
                    <img src={BoS2Image1} className="bo-s2-img1 owpimg-desktop2" alt='Just-casuals' />
                    {/* <img src={BoS2Image2} className="bo-s2-img2" alt='Just-casuals'  /> */}
                    <div className='bo-lott3 json3'>
                      <Lottie
                        loop
                        animationData={BOjson3}
                        play
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Section3 */}
          <div className='bo-s3'>
            <img src={BoS3Top} className="bo-s2-top" alt='Just-casuals' />
            <div className='container bo-s3-content'>
              <h3 className='workanimation'>
                If you meet our specifications mentioned above, <br className='d-none d-lg-block' />please write to us with the following details <br className='d-none d-lg-block' />and we will get back to you soon.
              </h3>
              <img src={BoS3Mid} className="img-fluid workanimation" alt='Just-casuals' />
              <form
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="on"
                name="point-to-point-new"
                id="point-to-point-new"
                method="POST"
                className="forms"
              >
                <div className='bo-forms'>
                  <fieldset className='bo-forms-spc workanimation'>
                    <label>Name</label>
                    <input
                      name='name'
                      className='fs-inpt'
                      type='text'
                      placeholder='Please enter your name'
                      {...register("name", {
                        required: {
                          value: true,
                          message: "Please enter your name",
                        },
                      })}
                    />{errors.name && (
                      <span className="errors">{errors.name.message}</span>)}
                  </fieldset>
                  <fieldset className='bo-forms-spc workanimation'>
                    <label>Email Id</label>
                    <input className='fs-inpt' type='text' name='email' placeholder='Please enter your email'    {...register("email", {
                        required: {
                          value: true,
                          message: "Please enter your email",
                        },pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",}
                      })}/> {errors.email && (
                        <span className="errors">{errors.email.message}</span>)}
                  </fieldset>
                </div>
                <div className='bo-forms'>
                  <fieldset className='bo-forms-spc workanimation'>
                    <label>Contact Number</label>
                    <input
                      name='number'
                      className='fs-inpt'
                      type='number'
                      placeholder='Please enter your contact number'{...register("phone", {
                        required: {
                          value: true,
                          message: "Please enter your contact number",
                        },
                        minLength: {
                          value: 6,
                          message: "Invalid phone number",
                        },
                        maxLength: {
                          value: 15,
                          message: "Invalid phone number",
                        },
                        pattern: {
                          value: /^-?[0-9]\d*\.?\d*$/i,
                          message: "Invalid",
                        },
                      })} 
                    /> {errors.phone && (
                      <span className="errors">{errors.phone.message}</span>  )}
                  </fieldset>
                  <fieldset className='bo-forms-spc workanimation'>
                    <label>Other brands and establishments in the area</label>
                    <input className='fs-inpt' type='text' name='store' placeholder='Nearby brands & establishments'
                      {...register("store", {
                        required: {
                          value: true,
                          message: "Please enter nearby brands & establishments",
                        },
                      })}
                    />
                    {errors.store && (
                      <span className="errors">{errors.store.message}</span>
                    )}
                  </fieldset>
                </div>

                <div className='bo-forms'>
                  <fieldset className='bo-forms-spc workanimation'>
                    <label>Location</label>
                    <input
                      name='location'
                      className='fs-inpt'
                      type='text'
                      placeholder='Address of the proposed store'
                      {...register("location", {
                        required: {
                          value: true,
                          message: "Please enter your location",
                        },
                      })} />
                    {errors.location && (
                      <span className="errors">{errors.location.message}</span>
                    )}
                  </fieldset>
                  <fieldset className='bo-forms-spc workanimation'>
                    <label>What's around the store</label>
                    <input className='fs-inpt' type='text' name='store1' placeholder='Basic population, colleges, brands present'
                      {...register("store1", {
                        required: {
                          value: true,
                          message: "Please enter basic population, colleges, etc.",
                        }
                      })} />
                    {errors.store1 && (
                      <span className="errors">{errors.store1.message}</span>
                    )}
                  </fieldset>
                </div>

                <div className='bo-forms'>
                  <fieldset className='bo-forms-spc workanimation'>
                    <label>Information of the city</label>
                    <input className='fs-inpt' type='text' name='city'

                      placeholder='Describe more about your city'
                      {...register("city", {
                        required: {
                          value: true,
                          message: "Please enter information of the city",
                        }
                      })} />
                    {errors.city && (
                      <span className="errors">{errors.city.message}</span>
                    )}
                  </fieldset>
                  <fieldset className='bo-forms-spc workanimation'>
                    <h5>Self owned or Leased?</h5>
                    <p>if leased the duration should be of a 9 year period.</p>
                    <div className='cb-input'>
                      <input type='checkbox' id='self-owned'
                        {...register("self_owned")} />
                      <label htmlFor='self owned'>Self Owned</label>
                    </div>
                    <div className='cb-input'>
                      <input type='checkbox' id='leased'   {...register("leased")} />
                      <label htmlFor='leased'>Leased</label>
                    </div>
                  </fieldset>
                  
                </div>
                <div className='bo-forms workanimation'>
                  <fieldset className='bo-forms-spc mrg-top workanimation'>
                    <h5>Upload Photos</h5>
                    <ul>
                      <li>Photographs of the store - internal and external</li>
                      <li>Photographs of the neighboring stores</li>
                    </ul>
                    <div className='cb-input-file'>

                      <FileUploader
                        {...register(`picture`)}
                        multiple={true}
                        types={fileTypes}
                        maxSize={5}
                        width={100}
                        name={`picture`}
                        id={`picture`}
                        handleChange={(val) => {
                          setValue(`picture`, val);
                          fill_val_addition_doc();
                        }}

                        label="Additional Documents allowed only * (pdf,png,jpeg) - Size(2M)"
                        className={` form-control ${errors.picture ? "is-invalid" : ""}`}

                      />
                      <div className="errors">
                        {errors.picture?.message}
                      </div>
                      <input style={{ backgroundColor: `#fffdf9`, opacity: `1`, border: `0` }}
                        {...register(`picture_text`)}
                        disabled
                        id={`picture_text`}
                        type="text"
                        name={`picture_text`}
                        placeholder=""
                        className={`form-control`}

                      />

                    </div>
                  </fieldset>
                  <div className='bo-forms-spc'></div>
                </div>
                <div className='bo-forms workanimation'>
                  
                  <div className='bo-forms-spc'>
                    <button
                      className='submit-btn'
                      id="submitDetails"
                      name="my-buttons"
                      ref={buttonRef}
                      type="submit"
                    >
                      {SubmitButton}
                    </button>

                  </div>
                </div>
              </form>


            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}
export default BusinessOppurtunities