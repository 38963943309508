import React, { Component } from 'react'
import Footer from '../components/footer';
import Header from '../components/header';
import AboutRTImg4 from './../assets/img/about-rt-img4.svg'

export default class Dummy extends Component {

  state = {
    leftGearRotation: 0,
    rightGearRotation: 0,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    window.requestAnimationFrame(() => {
      this.setState({
        leftGearRotation: window.pageYOffset,
        rightGearRotation: -window.pageYOffset,
      });
    });
  };

  render() {

    const { leftGearRotation, rightGearRotation } = this.state;

    return (
      
        <div>

            <Header />
            
            <div className='rr'>
                <img
                className='img-fluid'
                id="leftgear"
                src={AboutRTImg4}
                style={{ transform: `rotate(${leftGearRotation}deg)` }}
                alt="JC"
                />
                <img
                className='img-fluid'
                id="rightgear"
                src={AboutRTImg4}
                style={{ transform: `rotate(${-rightGearRotation}deg)` }}
                alt="JC"
                />
            </div>

            <Footer />
        </div>

    )
  }
}
